import React from "react";
import styled from "styled-components";
import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styles from "src/styles";

// Configs
import environmentConfig from "src/configs/environment.config";

// Components
import SocialButton from "src/components/shared/button/SocialButton";

// Assets
import logo from "src/assets/images/web_logo_wo_margin.png";
import logoGroup from "src/assets/images/web_logo_group.png";
import { ReactComponent as FacebookIcon } from "src/assets/icons/facebook.svg";

const DefaultMobileFooter = () => {
    return (
        <DivStyled className="default-desktop-footer">
            <Row>
                <Col span={24}>
                    <div className="logo">
                        <img className="web" src={logo} alt="logo" />
                        <img src={logoGroup} alt="logo" />
                    </div>
                </Col>
                <Col span={24} className="links">
                    <div className="column">
                        <div>
                            <Link to="/">
                                <FormattedMessage id="navbar.rentCars" />
                            </Link>
                        </div>
                        <div>
                            <Link to="/promotions">
                                <FormattedMessage id="navbar.promotions" />
                            </Link>
                        </div>
                        {/* <div>
                            <Link to="/our-services">
                                <FormattedMessage id="navbar.ourServices" />
                            </Link>
                        </div> */}
                        <div>
                            <Link to="/about-us">
                                <FormattedMessage id="navbar.aboutUs" />
                            </Link>
                        </div>
                        {/* <div>
                            <Link to="/rent-short-time">
                                <FormattedMessage id="navbar.rentShortTime" />
                            </Link>
                        </div>
                        <div>
                            <Link to="/rent-long-time">
                                <FormattedMessage id="navbar.rentLongTime" />
                            </Link>
                        </div>
                        <div>
                            <Link to="/rent-limousine">
                                <FormattedMessage id="navbar.rentLimousine" />
                            </Link>
                        </div>
                        <div>
                            <Link to="/driver-service">
                                <FormattedMessage id="navbar.driverService" />
                            </Link>
                        </div> */}
                    </div>
                    <div className="column">
                        {/* <div>
                            <Link to="/careers">
                                <FormattedMessage id="navbar.careers" />
                            </Link>
                        </div> */}
                        <div>
                            <Link to="/contact-us">
                                <FormattedMessage id="navbar.contactUs" />
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div className="text address">
                        <FormattedMessage tagName="div" id="footer.address.1" />
                        <FormattedMessage tagName="div" id="footer.address.2" />
                        <FormattedMessage tagName="div" id="footer.address.3" />
                    </div>
                    <div className="text contact">
                        <FormattedMessage
                            tagName="div"
                            id="footer.contact.officePhoneNoX"
                            values={{ value: "02-941-1344-8" }}
                        />
                        <FormattedMessage
                            tagName="div"
                            id="footer.contact.saleEmailX"
                            values={{ value: "sale@thai-prestige.com" }}
                        />
                        <FormattedMessage
                            tagName="div"
                            id="footer.contact.customerServiceEmailX"
                            values={{ value: "service@thai-prestige.co.th" }}
                        />
                        <FormattedMessage
                            tagName="div"
                            id="footer.contact.lineIdX"
                            values={{ value: "@thai-prestige" }}
                        />
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24} className="footer">
                    <div className="social-icons">
                        <SocialButton
                            target="_blank"
                            href={environmentConfig.SOCIAL_FACEBOOK}
                            rel="noreferrer"
                        >
                            <FacebookIcon />
                        </SocialButton>
                    </div>
                    <div className="policy">
                        <Link to="/term-of-use">Term of use</Link>
                        <svg
                            width="4"
                            height="4"
                            viewBox="0 0 4 4"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="2" cy="2" r="2" fill="#5E6F89" />
                        </svg>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </div>
                </Col>
            </Row>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.default-desktop-footer {
        background: #031630;
        min-height: 200px;
        margin-top: 50px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.6;

        .logo {
            .web {
                height: 50px;
            }

            img {
                margin: 0 4px;
    
                &:first-child {
                    margin-left: 0;
                }
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .text {
            color: ${styles.fontColorGray};

            > div {
                margin-top 9px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .address {
            margin-top: 60px;
        }

        .contact {
            margin-top: 18px;
        }

        .links {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
            column-gap: 80px;

            .column div {
                margin-top: 28px;
            }

            a {
                color: ${styles.fontColorWhite};
            }
        }

        .ant-divider {
            border-top-color: #9EB8BE;
            margin-top: 32px;
            margin-bottom: 18px;
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;

            .social-icons {
                display: flex;

                a {
                    margin-right: 20px;
                    display: flex;

                    &:last-child {
                        margin-right: 0;
                    }

                    svg {
                        height: 35px;
                        width: 35px;
                    }
                }
            }

            .policy {
                display: flex;
                align-items: center;

                a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: ${styles.fontColorGray};
                    cursor: pointer;
                    white-space: nowrap;

                    &:hover {
                        color: ${styles.fontColorWhite};
                    }
                }

                svg {
                    margin: 0 9px;
                }
            }
        }
    }
`;

export default DefaultMobileFooter;

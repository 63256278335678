import { CarSearchForm } from "src/components/booking/CarSearchCard";
import {
    BookingState,
    IdentifierType,
    TaxType,
    THAI_DIAL_CODE,
} from "src/constants";

export enum Action {
    INITIAL_FORM = "INITIAL_FORM",
    APPLYING_DISCOUNT = "APPLYING_DISCOUNT",
    APPLIED_DISCOUNT = "APPLIED_DISCOUNT",
    CLEAR_DISCOUNT = "CLEAR_DISCOUNT",
    SUBMIT_CAR_SERVICES = "SUBMIT_CAR_SERVICES",
    BACK_TO_CAR_SERVICES = "BACK_TO_CAR_SERVICES",
    SUBMIT_INFORMATION = "SUBMIT_INFORMATION",
    SUBMITTING_BOOKING = "SUBMITTING_BOOKING",
    SUBMIT_BOOKING_SUCCESS = "SUBMIT_BOOKING_SUCCESS",
    SUBMIT_BOOKING_FAILED = "SUBMIT_BOOKING_FAILED",
}

type ReducerAction =
    | {
          type: Action.INITIAL_FORM;
          payload: {
              bookingParams: BookingPageURLParams;
              carSearchForm: CarSearchForm;
              deliveryInfo?: BookingDeliveryFormData;
              carOffer: CarOffer;
              payType: string;
              pickUpLocation: string;
              returnLocation: string;
              provinces: Province[];
              districts: District[];
              subDistricts: SubDistrict[];
              locations: CarSearchLocation[];
              countries: Country[];
          };
      }
    | {
          type: Action.APPLYING_DISCOUNT;
      }
    | {
          type: Action.APPLIED_DISCOUNT;
          payload: BookingCoupon;
      }
    | {
          type: Action.CLEAR_DISCOUNT;
      }
    | {
          type: Action.SUBMIT_CAR_SERVICES;
          payload: {
              accessories: BookingAccessory[];
              deliveryInfo?: BookingDeliveryFormData;
              promotionCode?: string;
          };
      }
    | {
          type: Action.SUBMIT_INFORMATION;
          payload: InformationData;
      }
    | {
          type: Action.BACK_TO_CAR_SERVICES;
          payload: InformationData;
      }
    | {
          type: Action.SUBMITTING_BOOKING;
      }
    | {
          type: Action.SUBMIT_BOOKING_FAILED;
      }
    | {
          type: Action.SUBMIT_BOOKING_SUCCESS;
          payload: PaymentFormData;
      };

export const initialState: BookingFormReducerState = {
    initializing: true,
    processing: false,
    state: BookingState.CAR_SERVICES,
    bookingParams: {},
    carSearchForm: {},
    carOffer: undefined,
    payType: undefined,
    pickUpLocation: "",
    returnLocation: "",
    accessories: [],
    coupon: undefined,
    deliveryInfo: undefined,
    renterInfo: {
        identifier: IdentifierType.PERSONAL_CARD,
        countryCode: THAI_DIAL_CODE,
    },
    requireTax: false,
    taxInfo: {
        type: TaxType.PERSONAL,
        countryCode: THAI_DIAL_CODE,
    },
    requireFlight: false,
    flightInfo: undefined,
    districts: [],
    provinces: [],
    subDistricts: [],
    locations: [],
    countries: [],
    paymentData: undefined,
};

export const reducer = (
    state: BookingFormReducerState,
    action: ReducerAction
) => {
    switch (action.type) {
        case Action.INITIAL_FORM: {
            const {
                bookingParams,
                carSearchForm,
                deliveryInfo,
                carOffer,
                payType,
                pickUpLocation,
                returnLocation,
                districts,
                provinces,
                subDistricts,
                locations,
                countries,
            } = action.payload;
            return {
                ...state,
                initializing: false,
                state: BookingState.CAR_SERVICES,
                bookingParams,
                carSearchForm,
                deliveryInfo,
                carOffer,
                payType,
                pickUpLocation,
                returnLocation,
                districts,
                provinces,
                subDistricts,
                locations,
                countries,
            };
        }
        case Action.APPLYING_DISCOUNT: {
            return {
                ...state,
                processing: true,
            };
        }
        case Action.APPLIED_DISCOUNT: {
            return {
                ...state,
                coupon: action.payload,
                processing: false,
            };
        }
        case Action.CLEAR_DISCOUNT: {
            return {
                ...state,
                coupon: undefined,
            };
        }
        case Action.SUBMIT_CAR_SERVICES: {
            const { accessories, deliveryInfo, promotionCode } = action.payload;
            return {
                ...state,
                state: BookingState.INFORMATION,
                accessories,
                deliveryInfo,
                coupon:
                    state.coupon === undefined && promotionCode
                        ? { promotionCode }
                        : state.coupon,
            };
        }
        case Action.SUBMIT_INFORMATION: {
            const {
                flightInfo,
                renterInfo,
                taxInfo,
                requireFlight,
                requireTax,
            } = action.payload;
            return {
                ...state,
                flightInfo,
                renterInfo,
                taxInfo,
                requireFlight,
                requireTax,
            };
        }
        case Action.BACK_TO_CAR_SERVICES: {
            const {
                flightInfo,
                renterInfo,
                taxInfo,
                requireFlight,
                requireTax,
                promotionCode,
            } = action.payload;
            return {
                ...state,
                state: BookingState.CAR_SERVICES,
                flightInfo,
                renterInfo,
                taxInfo,
                requireFlight,
                requireTax,
                coupon:
                    state.coupon === undefined && promotionCode
                        ? { promotionCode }
                        : state.coupon,
            };
        }
        case Action.SUBMITTING_BOOKING: {
            return {
                ...state,
                processing: true,
            };
        }
        case Action.SUBMIT_BOOKING_SUCCESS: {
            return {
                ...state,
                paymentData: action.payload,
            };
        }
        case Action.SUBMIT_BOOKING_FAILED: {
            return {
                ...state,
                processing: false,
            };
        }
        default:
            throw new Error();
    }
};

import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import styles from "src/styles";

// Assets
import { ReactComponent as PhoneIcon } from "src/assets/icons/phone.svg";

const Contact = () => {
    return (
        <DivStyled className="contact">
            <PhoneIcon className="phone-icon" />
            <div className="text">
                <span>
                    <FormattedMessage id="header.callCenter" />:
                </span>
                <span>02-941-1344-8</span>
            </div>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.contact {
        height: 32px;
        font-size: 16px;
        line-height: 1.6;
        color: ${styles.fontColorGray};
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        .phone-icon {
            margin-right: 12px;
        }

        .text span {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

export default Contact;

import { createIntl, createIntlCache } from 'react-intl'

// Configs
import EnvConfig from "src/configs/environment.config";

// i18n
import message_en from 'src/i18n/en.json';
import message_th from 'src/i18n/th.json';

const messages = {
    en: message_en,
    th: message_th
}

const createInstance = (locale) => {
    const cache = createIntlCache()

    return createIntl({
        messages: messages[locale],
        locale
    }, cache)
}

export let intl = createInstance(EnvConfig.LOCALE.value)

export const changeLocale = (locale) => {
    intl = createInstance(locale)
}
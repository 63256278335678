import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PrivacyPolicyContent from "src/components/privacy-policy/PrivacyPolicyContent";
import { getClassName } from "src/helpers/common.helper";
import { acceptPDPA, getPDPA } from "src/services/app.service";
import styles from "src/styles";
import styled from "styled-components";

type Props = {
    className?: string;
    processing?: boolean;
    onAccept?: () => void;
};

const PDPAModal = ({
    className,
    processing = false,
    onAccept = () => {},
}: Props) => {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [pdpaObj, setPDPAObj] = useState<PDPA>({});

    useEffect(() => {
        const init = async () => {
            const { isAccepted, currentVersion, identifier } = await getPDPA();

            setPDPAObj({ currentVersion, identifier });
            if (!isAccepted) {
                setIsOpen(true);
            }
        };

        init();
    }, []);

    const handleAccept = () => {
        acceptPDPA(pdpaObj);
        setIsOpen(false);
        onAccept();
    };

    return (
        <ModalStyled
            className={getClassName("pdpa-modal", className)}
            closable={false}
            closeIcon={false}
            maskClosable={false}
            centered
            title={intl.formatMessage({ id: "privacyPolicy" })}
            footer={
                <Button
                    className="accept-btn"
                    disabled={processing}
                    onClick={handleAccept}
                >
                    <FormattedMessage id="accept" />
                </Button>
            }
            visible={isOpen}
            onOk={handleAccept}
        >
            <PrivacyPolicyContent />
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    &.pdpa-modal {
        width: 80% !important;

        .ant-modal-header {
            padding: 48px 48px 24px;
            border-bottom: none;

            @media screen and (max-width: 767px) {
                padding: 24px 24px 12px;
            }

            .ant-modal-title {
                font-weight: 400;
                font-size: 32px;
                line-height: 1.6;

                @media screen and (max-width: 767px) {
                    font-size: 24px;
                }
            }
        }

        .ant-modal-body {
            padding: 24px 48px;
            overflow-y: scroll;
            max-height: calc(80vh - 48px - 92px);
            color: ${styles.fontColorBlack};
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.6;

            @media screen and (max-width: 767px) {
                max-height: calc(80vh - 24px - 92px);
                padding: 12px 24px;
                font-size: 16px;
            }

            .paragraph {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .ant-modal-footer {
            padding: 24px 48px 48px;
            border-top: none;
            text-align: center;

            @media screen and (max-width: 767px) {
                padding: 12px 24px 24px;
            }

            .ant-btn.accept-btn {
                max-width: 296px;
                width: 100%;
                height: 44px;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 1.6;
                background: ${styles.colorBlue};
                color: ${styles.fontColorWhite};

                &:hover {
                    border-color: ${styles.colorLightBlue};
                    background: ${styles.colorLightBlue};
                }

                &:disabled {
                    background: ${styles.disabledBlue};
                    border: none;
                }

                svg {
                    margin-left: 14px;
                }
            }
        }
    }
`;

export default PDPAModal;

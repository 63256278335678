import React from "react";

const PrivacyPolicyContent = () => {
    return (
        <>
            <p
                className="has-line-data page-header bold"
                data-line-start="0"
                data-line-end="2"
            >
                นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)
                <br />
                สำหรับผู้สมัครงานและ/หรือพนักงาน บริษัท ไทย เพรสทิจ เร้นท์ เอ
                คาร์ จำกัด
            </p>
            <p className="has-line-data" data-line-start="3" data-line-end="4">
                บริษัท ไทย เพรสทิจ เร้นท์ เอ คาร์ จำกัด (“บริษัท”)
                ตระหนักถึงความสำคัญในการคุ้มครองข้อมูลส่วนบุคคล
                ของผู้สมัครงานและ/หรือพนักงานของบริษัทให้เป็นไปตามกฏหมาย
                ดังนั้นบริษัทจึงได้จัดทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลขึ้น
                โดยนโยบายนี้ได้อธิบายถึงวิธีการที่บริษัทปฏิบัติต่อข้อมูลส่วนบุคคล
                เช่น การเก็บรวบรวม การจัดเก็บรักษา การใช้ การเปิดเผย
                รวมถึงสิทธิต่างๆ ของเจ้าของข้อมูลส่วนบุคคล เป็นต้น
                เพื่อให้เจ้าของข้อมูลได้รับทราบถึงนโยบายในการคุ้มครองข้อมูลส่วนบุคคลของบริษัท
                บริษัทจึงประกาศนโยบาย ดังต่อไปนี้
            </p>
            <ol>
                <li
                    className="has-line-data"
                    data-line-start="4"
                    data-line-end="10"
                >
                    <p
                        className="has-line-data"
                        data-line-start="4"
                        data-line-end="9"
                    >
                        <span className="bold">คำนิยาม</span>
                        <br />
                        1.1 “ <span className="bold">บริษัท</span> ” หมายถึง
                        บริษัท ไทย เพรสทิจ เร้นท์ เอ คาร์ จำกัด
                        <br />
                        1.2 “ <span className="bold">เจ้าของข้อมูล</span> ”
                        หมายถึง พนักงาน ลูกจ้าง หรือผู้สมัครงานของบริษัท
                        ซึ่งเป็นเจ้าของข้อมูลส่วนบุคคล
                        <br />
                        1.3 “ <span className="bold">ข้อมูลส่วนบุคคล</span> ”
                        หมายถึง
                        ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
                        ไม่ว่าทางตรงหรือทางอ้อม
                        แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
                        ตามความหมายที่นิยามในกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                        <br />
                        1.4 “{" "}
                        <span className="bold">
                            ข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                        </span>{" "}
                        ” หมายถึง ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์
                        ศาสนา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
                        ความพิการ ลายนิ้วมือ ข้อมูลชีวภาพ เป็นต้น
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="10"
                    data-line-end="24"
                >
                    <p
                        className="has-line-data"
                        data-line-start="10"
                        data-line-end="23"
                    >
                        <span className="bold">
                            ข้อมูลส่วนบุคคลใดบ้างที่บริษัทเก็บรวบรวม ใช้
                            และ/หรือเปิดเผย
                        </span>
                        <br />
                        2.1 <span className="bold">ข้อมูลส่วนบุคคล</span> ได้แก่
                        ข้อมูลส่วนบุคคลที่เจ้าของข้อมูลให้ไว้แก่บริษัทโดยตรง
                        ทั้งการเก็บข้อมูลจาก
                        <br />
                        ใบสมัครงาน การสัมภาษณ์ หรือการจ้างงาน
                        รวมถึงข้อมูลส่วนบุคคลที่บริษัทได้รับหรือเข้าถึงได้จากแหล่งอื่น
                        ซึ่งมิใช่จากเจ้าของข้อมูลโดยตรง เช่น หน่วยงานของรัฐ
                        สถาบันการเงิน บริษัทในเครือ เป็นต้น
                        <br />
                        ข้อมูลส่วนบุคคลของเจ้าของข้อมูลที่บริษัทเก็บรวบรวม ใช้
                        และ/หรือเปิดเผย เช่น
                        <br />
                        • ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล ชื่อเล่น เพศ อายุ
                        วันที่และสถานที่เกิด หมู่โลหิต ส่วนสูง น้ำหนัก
                        <br />
                        ประวัติการศึกษา ประวัติการฝึกอบรม ใบรับรองวุฒิการศึกษา
                        ประวัติคุณสมบัติและประสบการณ์ทำงานโดยย่อ
                        ความสามารถทางภาษา ทักษะด้านคอมพิวเตอร์ ทักษะอื่นๆ
                        งานอดิเรก ประวัติการว่าจ้าง เลขบัตรประจำตัวประชาชน
                        เลขที่หนังสือเดินทาง เลขที่ใบอนุญาตขับขี่รถยนต์
                        สถานภาพทางการสมรส สถานภาพการเกณฑ์ทหาร เลขที่บัญชีธนาคาร
                        <br />
                        • ข้อมูลสำหรับการติดต่อ เช่น ที่อยู่ตามทะเบียนบ้าน
                        ที่อยู่ปัจจุบัน หมายเลขโทรศัพท์ ที่อยู่อีเมล ไลน์ไอดี
                        รวมถึงข้อมูลในโซเชียลมีเดียต่างๆ
                        <br />
                        • ข้อมูลเกี่ยวกับการจ้างงาน เช่น ตำแหน่งงาน สังกัดต่างๆ
                        ในองค์กร ค่าจ้าง ผลตอบแทนอื่น การ
                        <br />
                        ใช้สวัสดิการ การลงเวลาทำงาน การลางาน การแต่งตั้ง
                        การโยกย้าย การเปลี่ยนตำแหน่ง การประเมิน
                        <br />
                        ผลทดลองงาน การประเมินผลการปฏิบัติงาน
                        เหตุผลในการบอกเลิกสัญญาว่าจ้างและ/หรือลาออก
                        <br />
                        • ข้อมูลบุคคลที่สาม เช่น ข้อมูลสมาชิกในครอบครัว
                        ข้อมูลผู้รับผลประโยชน์จากสวัสดิการต่างๆ
                        ข้อมูลผู้ติดต่อฉุกเฉิน และบุคคลที่ท่านอ้างอิง
                        <br />
                        2.2{" "}
                        <span className="bold">
                            ข้อมูลส่วนบุคคลที่มีความอ่อนไหว คือ
                            ข้อมูลส่วนบุคคลที่กฏหมายกำหนดเป็นการเฉพาะ เช่น เชื้อ
                        </span>
                        <br />
                        ชาติ ศาสนา ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ
                        ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
                        หรือข้อมูลอื่นใดในทำนองเดียวกันที่กฏหมายกำหนด
                        ซึ่งบริษัทต้องดำเนินการด้วยความระมัดระวังเป็นพิเศษ
                        โดยบริษัทจะเก็บรวบรวม ใช้
                        และเปิดเผยข้อมูลส่วนบุคคลทีมีความอ่อนไหว
                        ต่อเมื่อบริษัทได้รับความยินยอมโดยชัดแจ้งจากเจ้าของข้อมูล
                        หรือในกรณีที่บริษัทมีความจำเป็นตามกรณีที่กฏหมายอนุญาต
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="24"
                    data-line-end="68"
                >
                    <p
                        className="has-line-data"
                        data-line-start="24"
                        data-line-end="38"
                    >
                        <span className="bold">
                            เหตุใดบริษัท จึงเก็บรวบรวม ใช้
                            และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        </span>
                        <br />
                        บริษัทอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผย
                        ข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        เพื่อวัตถุประสงค์ดังต่อไปนี้
                        <br />
                        3.1
                        <span className="bold">
                            วัตถุประสงค์ที่บริษัทจำเป็นต้องได้รับความยินยอม
                        </span>
                        <br />
                        บริษัทอาศัยความยินยอมของเจ้าของข้อมูลในการเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่เป็นข้อมูลอ่อนไหว
                        เพื่อวัตถุประสงค์ดังต่อไปนี้
                        <br />
                        (1) ข้อมูลชีวภาพ เช่น ลายพิมพ์นิ้วมือ ภาพสแกนใบหน้า
                        เพื่อการเข้าไปในอาคาร หรือเพื่อการเชื่อม
                        <br />
                        ต่อกับระบบงานต่างๆของบริษัท
                        <br />
                        (2)
                        ข้อมูลศาสนาตามที่ปรากฏรวมอยู่ในสำเนาบัตรประจำตัวประชาชน
                        เพื่อยืนยันและพิสูจน์ตัวบุคคล
                        <br />
                        (3) ประวัติอาชญากรรมและข้อมูลสุขภาพ
                        เพื่อการพิจารณาใบสมัครงานและตัดสินใจจ้างงาน
                        การคัดกรองประวัติ และการติดตามตรวจสอบ
                        <br />
                        3.2
                        <span className="bold">
                            วัตถุประสงค์ที่บริษัทอาจดำเนินการโดยอาศัยหลักเกณฑ์หรือฐานทางกฏหมายอื่นๆ
                        </span>
                        ในการเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        ซึ่งได้แก่
                        <br />
                        (1) เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญา
                        สำหรับการเข้าทำสัญญาจ้างงาน
                        หรือการปฏิบัติตามสัญญาจ้างงานกับเจ้าของข้อมูล
                        <br />
                        (2) เป็นการปฏิบัติหน้าที่ตามกฏหมาย
                        <br />
                        (3)
                        เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฏหมายของบริษัทหรือของบุคคลภายนอก
                        เพื่อให้สมดุลกับประโยชน์และสิทธิ
                        เสรีภาพขั้นพื้นฐานที่เกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        <br />
                        (4) เพื่อการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                        หรือสุขภาพของบุคคล และ/หรือ
                        <br />
                        (5) ประโยชน์สาธารณะ
                        สำหรับการดำเนินภารกิจเพื่อประโยชน์สาธารณะ
                        หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐ
                        บริษัทจะอาศัยหลักเกณฑ์หรือฐานทางกฏหมายข้างต้น
                        เพื่อเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        เพื่อวัตถุประสงค์ดังต่อไปนี้
                    </p>
                    <p
                        className="has-line-data"
                        data-line-start="39"
                        data-line-end="49"
                    >
                        <span className="underline bold">กรณีผู้สมัครงาน</span>
                        <br />
                        หากเจ้าของข้อมูล คือ ผู้สมัครงาน
                        บริษัทจะอาศัยหลักเกณฑ์หรือฐานทางกฏหมายในข้อ 3.2 (1) ถึง
                        (5)
                        <br />
                        ข้างต้น เพื่อการเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        เพื่อวัตถุประสงค์ดังต่อไปนี้
                        <br />
                        •
                        พิจารณาเกี่ยวกับการรับสมัครงานและประสานงานในการคัดเลือกเข้าทำงาน
                        <br />
                        • การยืนยันตัวตนและการติดต่อ
                        <br />
                        • การประเมินและให้คะแนนผู้สมัคร เพื่อตัดสินใจว่าจ้าง
                        <br />
                        • กำหนดเงินเดือนหรือค่าตอบแทน และอื่นๆ
                        <br />
                        • การคัดกรองประวัติ และตรวจสอบบุคคลอ้างอิง
                        ในกรณีที่เจ้าของข้อมูลได้รับข้อเสนอให้ทำงานกับบริษัท
                        <br />
                        • การติดต่อสื่อสารกับเจ้าของข้อมูล
                        และ/หรือผู้ที่เจ้าของข้อมูลกำหนดให้บริษัทติดต่อในกรณีฉุกเฉิน
                        <br />• วัตถุประสงค์อื่นๆ
                        ที่บริษัทต้องการอย่างสมเหตุสมผล
                        ตามที่ระบุไว้ในใบสมัครงานหรือเอกสารที่เกี่ยวข้องใดๆ
                    </p>
                    <p
                        className="has-line-data"
                        data-line-start="50"
                        data-line-end="67"
                    >
                        <span className="underline bold">
                            กรณีพนักงานของบริษัท
                        </span>
                        <br />
                        หากเจ้าของข้อมูลคือพนักงานของบริษัท
                        บริษัทจะอาศัยหลักเกณฑ์หรือฐานทางกฏหมายข้อ 3.2 (1) ถึง
                        <br />
                        (5) ข้างต้น เพื่อการเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        เพื่อวัตถุประสงค์ดังต่อไปนี้
                        <br />
                        • การสรรหาบุคลากร เช่น
                        การตัดสินใจจ้างหรือเปลี่ยนแปลงประเภทของสัญญาจ้างงาน
                        <br />
                        • การบริหารจัดการภายในองค์กร เช่น
                        การจัดโครงสร้างอัตรากำลัง การโอนย้าย
                        การเปลี่ยนหน้าที่งาน การปรับระดับพนักงาน การเกษียณอายุ
                        เป็นต้น
                        <br />
                        • การจัดให้มีการฝึกอบรม และพัฒนาบุคลากร เช่น การปฐมนิเทศ
                        การจัดอบรมหลักสูตรทั้งภายในและภายนอก การทำทะเบียนการอบรม
                        การยื่นรับรองหลักสูตรและค่าใช้จ่ายการฝึกอบรมจากหน่วยงานราชการ
                        เป็นต้น
                        <br />
                        • การจ่ายเงินเดือน ค่าตอบแทน และการให้ผลประโยชน์ต่างๆ
                        เช่น ค่าจ้าง อัตราการขึ้นเงินเดือน โบนัส การหักลดภาษี
                        กองทุนสำรองเลี้ยงชีพ ประกันสังคม
                        การจัดให้มีสวัสดิการและสิทธิประโยชน์อื่นของพนักงาน
                        <br />
                        •
                        การบริหารจัดการการลาให้สอดคล้องกับข้อบังคับการทำงานของบริษัท
                        <br />
                        • การติดต่อสื่อสาร รวมถึงการให้การอ้างอิงและคำแนะนำ
                        <br />
                        • วัตถุประสงค์ทางด้านสถิติและการวิเคราะห์
                        เพื่อการพัฒนาบุคลากรและปรับปรุงกระบวนการทำงาน
                        <br />
                        • การปฏิบัติตามภาระหน้าที่ทางกฏหมาย เช่น
                        ข้อกำหนดเกี่ยวกับแรงงาน สุขอนามัย และความปลอดภัย
                        หรือตามที่หน่วยงานของรัฐร้องขอ
                        <br />
                        • การจัดเก็บประวัติการดำเนินการทางวินัยต่อพนักงาน
                        เพื่อการบริหารจัดการอย่างมีประสิทธิภาพ
                        หรือการกำหนดมาตรการทางวินัยเมื่อจำเป็น
                        <br />
                        •
                        การดำเนินการตรวจสอบภายในเพื่อติดตามเรื่องร้องเรียนหรือการเรียกร้อง
                        ติดตามพฤติกรรมที่ไม่เหมาะสมของพนักงานและป้องกันการฉ้อโกง
                        <br />
                        • การติดต่อในกรณีฉุกเฉินไปยังบุคคลที่เจ้าของข้อมูลกำหนด
                        <br />
                        • การป้องกันกิจกรรมของพนักงานซึ่งไม่ชอบด้วยกฏหมาย
                        หรือการละเลยหน้าที่
                        <br />
                        • การคุ้มครองความลับของข้อมูลและสินทรัพย์ของบริษัท
                        <br />•
                        วัตถุประสงค์อื่นๆที่เกี่ยวข้องกับการจ้างงานของเจ้าของข้อมูล
                        เช่น การดำเนินกิจกรรมหรือการดำเนินงาน
                        เพื่อหรือในนามบริษัท
                        หรือตามที่ระบุไว้ในสัญญาจ้างงานของเจ้าของข้อมูล
                        ข้อบังคับการทำงานหรือเอกสารใดๆที่เกี่ยวข้องกับทรัพยากรบุคคล
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="68"
                    data-line-end="77"
                >
                    <p
                        className="has-line-data"
                        data-line-start="68"
                        data-line-end="76"
                    >
                        <span className="bold">การเปิดเผยข้อมูลส่วนบุคคล</span>
                        <br />
                        บริษัทจะเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        ตามวัตถุประสงค์ที่บริษัทได้แจ้งแก่เจ้าของข้อมูลเท่านั้น
                        <br />
                        โดยบริษัทจะเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลในกรณีดังต่อไปนี้
                        <br />
                        4.1 บริษัทได้รับความยินยอมจากเจ้าของข้อมูล
                        <br />
                        4.2
                        เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาหรือตามคำขอของเจ้าของข้อมูล
                        รวมทั้งเปิดเผยเพื่อให้การทำธุรกรรมหรือกิจกรรมใดๆของเจ้าของข้อมูลสามารถดำเนินการได้
                        โดยบรรลุวัตถุประสงค์ของเจ้าของข้อมูล
                        <br />
                        4.3
                        เป็นการจำเป็นในการเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลให้แก่บริษัทในเครือ
                        โดยการเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลดังกล่าว
                        บริษัทจะดำเนินการให้บุคคลเหล่านั้นเก็บรักษาข้อมูลส่วนบุคคลไว้เป็นความลับและไม่นำไปใช้เพื่อวัตถุประสงค์อื่นนอกเหนือจากขอบเขตที่บริษัทกำหนดไว้
                        <br />
                        4.4
                        เป็นการปฏิบัติตามกฏหมายหรือกฏเกณฑ์ทางการหรือคำสั่งของหน่วยงานที่มีอำนาจกำกับดูแล
                        หรือหน่วยงานทางการที่มีอำนาจตามกฏหมาย เช่น กระทรวงแรงงาน
                        สำนักงานประกันสังคม กรมพัฒนาฝีมือแรงงาน กรมบังคับคดี
                        กองทุนเงินให้กู้ยืมเพื่อการศึกษา ศาล
                        หรือหน่วยงานราชการอื่นใด ตามที่กฏหมายกำหนด เป็นต้น
                        <br />
                        4.5 เปิดเผยให้แก่บุคคลหรือนิติบุคคล หรือองค์กรอื่นใด
                        เช่น ธนาคารและสถาบันการเงิน
                        ผู้ให้บริการในการจัดทำบัญชีเงินเดือน
                        ผู้ให้บริการกองทุนสำรองเลี้ยงชีพ
                        ผู้ให้บริการงานด้านทรัพยากรบุคคลที่เป็นบุคคลภายนอก
                        ผู้ให้บริการระบบเทคโนโลยีสารสนเทศและบริษัทที่ให้บริการสนับสนุนด้านเทคโนโลยีสารสนเทศ
                        บริษัทประกันภัย โรงพยาบาล
                        ผู้ให้บริการด้านการฝึกอบรม/ให้ความรู้ เป็นต้น
                        ซึ่งในการให้บริการดังกล่าว
                        ผู้ให้บริการอาจสามารถเข้าถึงข้อมูลส่วนบุคคลของเจ้าของข้อมูลได้
                        อย่างไรก็ตาม
                        บริษัทจะให้ข้อมูลแก่ผู้ให้บริการเฉพาะข้อมูลที่จำเป็นสำหรับผู้ให้บริการในการให้บริการเท่านั้น
                        และบริษัทได้ขอบุคคลดังกล่าวไม่ให้จัดเก็บ ใช้ เปิดเผย
                        หรือโอน(ในประเทศหรือระหว่างประเทศ)
                        ซึ่งข้อมูลของเจ้าของข้อมูลเพื่อวัตถุประสงค์อื่น
                        บริษัทจะกำกับดูแลให้ผู้ให้บริการที่บริษัททำงานด้วยเก็บรักษาข้อมูลส่วนบุคคลของเจ้าของข้อมูลไว้อย่างปลอดภัยตามที่กฎหมายกำหนดไว้
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="77"
                    data-line-end="85"
                >
                    <p
                        className="has-line-data"
                        data-line-start="77"
                        data-line-end="84"
                    >
                        <span className="bold">
                            ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล
                        </span>
                        <br />
                        บริษัทจะจัดเก็บข้อมูลส่วนบุคคลของเจ้าของข้อมูลไว้ในระยะเวลาเท่าที่จำเป็น
                        เพื่อให้บรรลุวัตถุประสงค์ที่
                        <br />
                        กำหนดไว้ในนโยบายฉบับนี้
                        ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น
                        หากมีกฏหมายกำหนดหรืออนุญาตไว้ เช่น
                        <br />
                        5.1 ผู้สมัครงาน บริษัทจะจัดเก็บข้อมูลการสมัครงาน
                        เพื่อพิจารณาการรับเข้าทำงานของเจ้าของข้อมูล ภายใน 30 วัน
                        นับแต่บริษัทได้รับข้อมูลดังกล่าว
                        <br />
                        5.2 พนักงาน บริษัทจัดเก็บไว้ตามกฏหมายคุ้มครองแรงงาน
                        เพื่อวัตถุประสงค์ในการพิสูจน์ตรวจสอบ
                        กรณีอาจเกิดข้อพิพาทภายในอายุความตามที่กฏหมายกำหนดเป็นระยะเวลาไม่เกิน
                        10 ปี
                        <br />
                        ทั้งนี้เมื่อสิ้นสุดระยะเวลาการจัดเก็บข้อมูลส่วนบุคคลดังกล่าว
                        บริษัทจะดำเนินการลบ หรือทำลายข้อมูลส่วน
                        <br />
                        บุคคล
                        หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลได้
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="85"
                    data-line-end="89"
                >
                    <p
                        className="has-line-data"
                        data-line-start="85"
                        data-line-end="88"
                    >
                        <span className="bold">
                            การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
                        </span>
                        <br />
                        บริษัทตระหนักถึงความสำคัญของการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                        <br />
                        บริษัทจึงกำหนดให้มีมาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลอย่างเหมาะสม
                        เพื่อป้องกันการสูญหาย การเข้าถึง ทำลาย ใช้ เปลี่ยนแปลง
                        แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคล
                        โดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฏหมาย
                        เพื่อให้เป็นไปตามที่กำหนดในนโยบาย
                        และ/หรือแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของบริษัท
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="89"
                    data-line-end="103"
                >
                    <p
                        className="has-line-data"
                        data-line-start="89"
                        data-line-end="102"
                    >
                        <span className="bold">
                            สิทธิของเจ้าของข้อมูลส่วนบุคคล
                        </span>
                        <br />
                        ภายใต้บทบัญญัติแห่งกฏหมายและข้อยกเว้นตามกฏหมายที่เกี่ยวข้อง
                        เจ้าของข้อมูลมีสิทธิตามที่ระบุไว้ดัง
                        <br />
                        ต่อไปนี้
                        <br />
                        7.1 <span className="bold">สิทธิขอเข้าถึงข้อมูล</span>
                        เจ้าของข้อมูลมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคล
                        หรือขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับตนซึ่งอยู่ในความรับผิดชอบของบริษัท
                        ทั้งนี้ เพื่อความเป็นส่วนตัวและความปลอดภัยของข้อมูล
                        บริษัทอาจขอให้เจ้าของข้อมูลพิสูจน์ตัวตนก่อนจะให้ข้อมูลตามที่ขอ
                        <br />
                        7.2 <span className="bold">สิทธิขอให้แก้ไขข้อมูล</span>
                        ในกรณีที่เจ้าของข้อมูลเห็นว่าข้อมูลส่วนบุคคลใดที่เกี่ยวกับตนเองไม่ถูกต้อง
                        ไม่เป็นปัจจุบัน ไม่สมบูรณ์
                        หรืออาจก่อให้เกิดความเข้าใจผิด
                        เจ้าของข้อมูลสามารถขอให้บริษัทดำเนิน
                        <br />
                        การแก้ไข เพื่อให้ข้อมูลถูกต้อง เป็นปัจจุบัน
                        สมบูรณ์และไม่ก่อให้เกิดความเข้าใจผิดได้
                        <br />
                        7.3 <span className="bold">สิทธิขอถ่ายโอนข้อมูล</span>
                        เจ้าของข้อมูลมีสิทธิขอรับข้อมูลส่วนบุคคลที่บริษัทมีเกี่ยวกับตนในรูปแบบที่มีการจัดระเบียบแล้วและสามารถอ่านได้ในรูปแบบอิเล็กทรอนิกส์
                        และเพื่อส่งหรือโอนข้อมูลดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
                        โดยต้องเป็นข้อมูลส่วนบุคคลที่เจ้าของข้อมูลได้ให้กับบริษัท
                        และ
                        กรณีที่บริษัทได้รับความยินยอมจากเจ้าของข้อมูลในการเก็บรวบรวม
                        ใช้ และ/หรือเปิดเผย
                        หรือเพื่อปฏิบัติตามสัญญาที่บริษัทมีกับเจ้าของข้อมูล
                        <br />
                        7.4{" "}
                        <span className="bold">
                            สิทธิขอคัดค้าน เจ้าของข้อมูลมีสิทธิขอคัดค้าน
                        </span>
                        หากการเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลที่ทำขึ้นเพื่อประโยชน์โดยชอบด้วยกฏหมายของบริษัทหรือของบุคคลอื่น
                        หรือ
                        <br />
                        เพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
                        หากท่านยื่นคัดค้าน บริษัทจะยังคงดำเนินการเก็บรวบรวม ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านต่อไปเฉพาะที่บริษัทสามารถแสดงเหตุผลตามกฏหมายได้ว่ามีความสำคัญยิ่งกว่าสิทธิขั้นพื้นฐานของท่าน
                        หรือเป็นไปเพื่อการยืนยันการปฏิบัติตามกฏหมาย
                        หรือการต่อสู้ในการฟ้องร้องตามกฏหมาย ตามแต่กรณี
                        <br />
                        7.5{" "}
                        <span className="bold">
                            สิทธิขอให้ระงับการใช้ข้อมูล
                        </span>
                        เจ้าของข้อมูลมีสิทธิขอให้บริษัทระงับใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่
                        บริษัทอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้าน
                        หรือกรณีอื่นใด
                        ที่บริษัทหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของเจ้าของข้อมูลตามกฏหมายที่เกี่ยวข้อง
                        แต่เจ้าของข้อมูลขอให้บริษัทระงับการใช้แทน
                        <br />
                        7.6 <span className="bold">สิทธิขอถอนความยินยอม</span>
                        เจ้าของข้อมูลมีสิทธิขอเพิกถอนความยินยอมที่ให้บริษัทเก็บรวบรวม
                        ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของตนเมื่อใดก็ได้
                        เว้นแต่การเพิกถอนความยินยอมจะมีข้อจำกัดโดยกฏหมายหรือสัญญาที่ให้ประโยชน์แก่เจ้าของข้อมูล
                        เช่น เจ้าของข้อมูลยังมีสัญญาจ้างกับบริษัท
                        หรือยังมีภาระหนี้หรือภาระผูกพันตามกฏหมายอยู่กับบริษัท
                        ทั้งนี้
                        การเพิกถอนความยินยอมดังกล่าวอาจทำให้เจ้าของข้อมูลไม่สามารถรับบริการหรือทำธุรกรรมกับบริษัทได้
                        หรืออาจทำให้บริการที่จะได้รับจากบริษัทไม่มีประสิทธิภาพเท่าที่ควร
                        <br />
                        7.7{" "}
                        <span className="bold">
                            สิทธิขอให้ลบหรือทำลายข้อมูล
                        </span>
                        เจ้าของข้อมูลมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของตน
                        หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
                        หากเจ้าของข้อมูลเชื่อว่าข้อมูลส่วนบุคคลของตนถูกเก็บรวบรวม
                        ใช้ และ/หรือเปิดเผยโดยไม่ชอบด้วยกฏหมายที่เกี่ยวข้อง
                        หรือเห็นว่าบริษัทหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้
                        หรือเมื่อเจ้าของข้อมูลได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิคัดค้านตามที่แจ้งไว้ข้างต้น
                        <br />
                        7.8{" "}
                        <span className="bold">สิทธิยื่นเรื่องร้องเรียน</span>
                        เจ้าของข้อมูลมีสิทธิยื่นเรื่องร้องเรียนไปยังหน่วยงานที่มีอำนาจในกรณีที่เชื่อว่าการเก็บรวบรวม
                        ใช้
                        และ/หรือเปิดเผยข้อมูลส่วนบุคคลของบริษัทนั้นไม่ชอบด้วยกฏหมาย
                        หรือไม่สอดคล้องกับกฏหมายคุ้มครองข้อมูลที่บังคับใช้
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="103"
                    data-line-end="107"
                >
                    <p
                        className="has-line-data"
                        data-line-start="103"
                        data-line-end="106"
                    >
                        <span className="bold">
                            การทบทวนและเปลี่ยนแปลงนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                        </span>
                        <br />
                        บริษัทอาจทำการปรับปรุงหรือแก้ไขนโยบายฉบับนี้เป็นครั้งคราวเพื่อให้สอดคล้องกับข้อกำหนดตามกฏหมาย
                        <br />
                        การเปลี่ยนแปลงการดำเนินงานของบริษัท
                        รวมถึงข้อเสนอแนะและความคิดเห็นจากหน่วยงานต่างๆ
                        โดยบริษัทจะประกาศแจ้งการเปลี่ยนแปลงให้ทราบอย่างชัดเจน
                    </p>
                </li>
                <li
                    className="has-line-data"
                    data-line-start="107"
                    data-line-end="115"
                >
                    <p
                        className="has-line-data"
                        data-line-start="107"
                        data-line-end="114"
                    >
                        <span className="bold">ช่องทางการติดต่อบริษัท</span>
                        <br />
                        ฝ่ายบุคคล
                        <br />
                        บริษัท ไทย เพรสทิจ เร้นท์ เอ คาร์ จำกัด
                        <br />
                        110 ถนนวิภาวดีรังสิต แขวงลาดยาว
                        <br />
                        เขตจตุจักร กรุงเทพมหานคร 10900
                        <br />
                        โทรศัพท์ 02-941-1344-8 ,02-941-2231-3
                        <br />
                        อีเมล{" "}
                        <a href="mailto:wararat.hr@thai-prestigec.com">
                            wararat.hr@thai-prestigec.com
                        </a>
                    </p>
                </li>
            </ol>
        </>
    );
};

export default PrivacyPolicyContent;

import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { AxiosResponse } from "axios";

// Constants
import {
    BRANCH_AVAILABLES_TIMES,
    CarLocationServices,
    CAR_SIZES,
} from "src/constants";

// Configs
import { intl } from "src/configs/i18n.config";

// Helpers
import { AxiosCustomResponse } from "src/helpers/axios.helper";
import { mergeDateTime } from "src/helpers/date.helper";
import { getPaginationFromHeader } from "src/helpers/common.helper";

// Components
import {
    CarLocationServiceForm,
    CarSearchForm,
} from "src/components/booking/CarSearchCard";
import { CarOfferFiltersFormData } from "src/components/booking/CarOfferFilters";

// APIs
import * as rentalCarsAPI from "src/apis/rental-cars/cars.api";
import * as bookingAPI from "src/apis/booking.api";
import * as branchesAPI from "src/apis/branches.api";

export const getCarSearchServiceType = (
    obj:
        | Record<
              CarLocationServices.DELIVERY | CarLocationServices.SAME_LOCATION,
              boolean
          >
        | undefined
): CarSearchServiceType => {
    if (obj === undefined) {
        return CarLocationServices.DIFFERENT_LOCATIONS;
    } else if (obj.delivery) {
        return CarLocationServices.DELIVERY;
    } else if (obj.sameLocation) {
        return CarLocationServices.SAME_LOCATION;
    } else {
        return CarLocationServices.DIFFERENT_LOCATIONS;
    }
};

const _transformDeliveryLocationToSearchAddress = ({
    district,
    id,
    label,
    postal: postalCode,
    province,
    sub_district: subDistrict,
}: FetchDeliveryLocationResponse): SearchAddress => ({
    id,
    label,
    postalCode,
    province,
    subDistrict,
    district,
});

export const fetchAddresses = async ({
    limit = 20,
    location,
    offset = 0,
}: Omit<FetchDeliveryLocationsParams, "location"> & {
    location?: string;
}): Promise<AxiosCustomResponse<SearchAddress[]>> => {
    if (!location) {
        return { payload: [], success: true };
    }

    const { payload } = await bookingAPI.fetchDeliveryLocations({
        location,
        limit,
        offset,
    });

    const result = payload?.map(_transformDeliveryLocationToSearchAddress);
    return { payload: result, success: true };
};

export const fetchAddress = async (
    id: number
): Promise<AxiosCustomResponse<SearchAddress>> => {
    const { payload } = await bookingAPI.fetchDeliveryLocation(id);

    if (!payload) {
        return { payload: undefined, success: false };
    }

    return {
        payload: _transformDeliveryLocationToSearchAddress(payload),
        success: true,
    };
};

export const transformAddressToOption = (item: SearchAddress): SelectOption => {
    const { id, label } = item;
    return {
        value: id,
        label,
        payload: item,
    };
};

export const fetchCarServiceLocationOptions = async (): Promise<
    AxiosCustomResponse<CarSearchLocation[]>
> => {
    const { payload } = await branchesAPI.fetchBranches();
    const result = payload?.map<CarSearchLocation>(
        ({ branch_id: id, name: branch }) => ({
            id,
            branch,
        })
    );

    return { payload: result, success: true };
};

export const transformCarSearchLocationToOption = (
    item: CarSearchLocation
): SelectOption => {
    const { id, branch } = item;
    return {
        value: id,
        label: branch,
    };
};

export const disableDate = (date: any): boolean => {
    const value = date as Dayjs;
    const isTodayOrPast = value.isBefore(dayjs().add(1, "day").startOf("day"));
    const isMoreThanSixMonths = value.isAfter(
        dayjs().add(6, "month").startOf("day")
    );
    return isTodayOrPast || isMoreThanSixMonths;
};

export const fetchCarSizes = async (): Promise<
    AxiosCustomResponse<CarSize[]>
> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const result = CAR_SIZES.map((item) => ({
        ...item,
        name: intl.formatMessage({ id: `carSize.${item.id}` }),
    }));

    return { payload: result, success: true };
};

export const initialPage = async (params: {
    pickUpLocationId?: number;
    returnLocationId?: number;
}): Promise<BookingPageInitialState> => {
    const { pickUpLocationId, returnLocationId } = params;

    const [
        branchesResponse,
        sizeListResponse,
        pickUpLocationResponse,
        returnLocationResponse,
    ] = await Promise.all([
        fetchCarServiceLocationOptions(),
        fetchCarSizes(),
        pickUpLocationId !== undefined
            ? fetchAddress(pickUpLocationId)
            : undefined,
        returnLocationId !== undefined
            ? fetchAddress(returnLocationId)
            : undefined,
    ]);

    return {
        locations: branchesResponse.success
            ? (branchesResponse.payload as CarSearchLocation[])
            : [],
        sizeList: sizeListResponse.success
            ? (sizeListResponse.payload as CarSize[])
            : [],
        pickUpLocationOption: pickUpLocationResponse?.payload
            ? transformAddressToOption(pickUpLocationResponse.payload)
            : undefined,
        returnLocationOption: returnLocationResponse?.payload
            ? transformAddressToOption(returnLocationResponse.payload)
            : undefined,
    };
};

export const getCarSearchLocationObject = ({
    locations,
    serviceType,
}: CarSearchForm): CarLocationServiceForm => {
    if (serviceType?.sameLocation) {
        return locations?.sameLocation!;
    } else if (serviceType?.delivery) {
        return locations?.delivery!;
    } else {
        return locations?.differentLocations!;
    }
};

export const getCarSearchFormValues = (
    formValues: CarSearchForm
): {
    branchId: number;
    returnBranchId: number;
    pickUpDate: Dayjs;
    returnDate: Dayjs;
} => {
    const {
        pickUpLocation,
        pickUpDate,
        pickUpTime,
        returnLocation,
        returnDate,
        returnTime,
    } = getCarSearchLocationObject(formValues);

    const branchId = pickUpLocation as number;

    return {
        branchId,
        pickUpDate: mergeDateTime(pickUpDate as Dayjs, pickUpTime!),
        returnBranchId: returnLocation ?? branchId,
        returnDate: mergeDateTime(returnDate as Dayjs, returnTime!),
    };
};

const getSearchCarOffersCriteria = (
    formValues: CarSearchForm,
    { numberOfSeats, sizeList = [], sortBy }: CarOfferFiltersFormData,
    offset?: number,
    limit?: number
): FetchCarsOffersRequestParams => {
    const { branchId, pickUpDate, returnDate } =
        getCarSearchFormValues(formValues);
    const [sort_by, order_by] = sortBy.split("_");

    return {
        branch: branchId,
        pick_up_date: pickUpDate.valueOf(),
        returned_date: returnDate.valueOf(),
        number_of_seats: numberOfSeats === -1 ? undefined : numberOfSeats,
        sizes: sizeList,
        limit,
        offset,
        order_by,
        sort_by,
    };
};

const _transformCarOffers = (arr: FetchCarsOffersResponse = []): CarOffer[] => {
    return arr.map<CarOffer>(
        ({
            car_specs_id: carSpecsId,
            image_url: imageUrl,
            brand,
            model,
            size,
            cubic_centimeters: cubicCentimeters,
            transmission_gearbox: transmissionGearbox,
            number_of_doors: numberOfDoors,
            number_of_seats: numberOfSeats,
            truck,
            insurance,
            offers: {
                pay_now: payNow,
                pay_on_counter: payOnCounter,
                special_deal: specialDeal,
            },
        }) => ({
            carSpecsId,
            imageUrl,
            brand,
            model,
            size,
            cubicCentimeters,
            transmissionGearbox,
            numberOfDoors,
            numberOfSeats,
            truck,
            insurance,
            offers: {
                payNow: parseInt(payNow),
                payOnCounter: parseInt(payOnCounter),
                specialDeal: specialDeal ? parseInt(specialDeal) : undefined,
            },
        })
    );
};

export const searchOffers = async (
    formValues: CarSearchForm,
    filters: CarOfferFiltersFormData,
    offset: number,
    limit: number = 10
): Promise<
    AxiosCustomResponse<{ data: CarOffer[]; pagination: Pagination }>
> => {
    const params = getSearchCarOffersCriteria(
        formValues,
        filters,
        offset,
        limit
    );

    const {
        payload = [],
        success,
        response,
    } = await rentalCarsAPI.fetchOffers(params);
    const pagination = getPaginationFromHeader(
        response as AxiosResponse,
        limit
    );

    if (!success) {
        return {
            success: true,
            payload: {
                data: [],
                pagination,
            },
        };
    }

    return {
        success: true,
        payload: {
            data: _transformCarOffers(payload),
            pagination,
        },
    };
};

export const parseCarSearchFormToUrlParams = (
    values: CarSearchForm
): string => {
    const serviceType = getCarSearchServiceType(values.serviceType);
    const { pickUpDate, returnDate, ...formValues } =
        getCarSearchLocationObject(values);
    const params = {
        serviceType,
        pickUpDate: `${pickUpDate?.valueOf()}`,
        returnDate: `${returnDate?.valueOf()}`,
        ...formValues,
    } as BookingPageURLParams;

    return queryString.stringify(params);
};

export const parseBookingUrlParamsToForm = (
    params: BookingPageURLParams
): CarSearchForm => {
    const {
        serviceType,
        pickUpDate: defaultPickUpDate,
        pickUpTime,
        pickUpLocation,
        returnDate: defaultReturnDate,
        returnLocation,
        returnTime,
    } = params;
    const type =
        [CarLocationServices.SAME_LOCATION, CarLocationServices.DELIVERY].find(
            (value) => value === serviceType
        ) ?? CarLocationServices.DIFFERENT_LOCATIONS;

    const pickUpDate =
        defaultPickUpDate === undefined
            ? dayjs()
            : dayjs(parseInt(defaultPickUpDate));
    const returnDate =
        defaultReturnDate === undefined
            ? dayjs()
            : dayjs(parseInt(defaultReturnDate));

    return {
        serviceType: type ? { [type]: true } : undefined,
        locations: {
            [type]: {
                pickUpLocation:
                    pickUpLocation === undefined
                        ? undefined
                        : parseInt(pickUpLocation),
                pickUpDate: pickUpDate.isBefore(dayjs().startOf("date"))
                    ? undefined
                    : pickUpDate,
                returnLocation:
                    returnLocation === undefined
                        ? undefined
                        : parseInt(returnLocation),
                returnDate: returnDate.isBefore(dayjs().startOf("date"))
                    ? undefined
                    : returnDate,
                pickUpTime: BRANCH_AVAILABLES_TIMES.find(
                    (value) => value === pickUpTime
                ),
                returnTime: BRANCH_AVAILABLES_TIMES.find(
                    (value) => value === returnTime
                ),
            },
        },
    } as CarSearchForm;
};

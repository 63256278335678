import React, { Suspense, Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import DefaultLayout from "src/components/layout/default/DefaultLayout";

// Components
import ScrollToTop from "src/components/router/ScrollToTop";
import LoadingScreen from "src/components/shared/loading/LoadingScreen";
import BookingFormProvider from "src/containers/booking-form/BookingFormProvider";

const PageNotFound = React.lazy(() => import("src/containers/error/PageNotFound"));

class Router extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorUi: false,
        };
    }

    static getDerivedStateFromError() {
        return {
            errorUi: true
        };
    }

    render() {
        const { errorUi } = this.state;

        if (errorUi) {
            return <div>Error</div>;
        }

        const notFound = <Route component={PageNotFound} />;

        return (
            <ScrollToTop>
                <DefaultLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Switch>
                            <Route exact path="/" component={React.lazy(() => import("src/containers/home/HomePage"))} />
                            <Route exact path="/long-term" component={React.lazy(() => import("src/containers/long-term/LongTermPage"))} />
                            <Route exact path="/promotions" component={React.lazy(() => import("src/containers/promotions/PromotionsPage"))} />
                            <Route exact path="/promotions/:id" component={React.lazy(() => import("src/containers/promotions/PromotionDetailPage"))} />
                            <Route exact path="/booking" component={React.lazy(() => import("src/containers/booking/BookingPage"))} />
                            <Route exact path="/about-us" component={React.lazy(() => import("src/containers/about-us/AboutUsPage"))} />
                            <Route exact path="/careers" component={React.lazy(() => import("src/containers/careers/CareersPage"))} />
                            <Route exact path="/careers/:id" component={React.lazy(() => import("src/containers/careers/CareersDetailPage"))} />
                            <Route exact path="/contact-us" component={React.lazy(() => import("src/containers/contact-us/ContactUsPage"))} />
                            <Route exact path="/tracking" component={React.lazy(() => import("src/containers/tracking/TrackingPage"))} />
                            <Route exact path="/tracking/:refNo" component={React.lazy(() => import("src/containers/tracking/TrackingDetailPage"))} />
                            <Route exact path="/payment/landing" component={React.lazy(() => import("src/containers/payment/PaymentLandingPage"))} />
                            <Route exact path="/term-of-use" component={React.lazy(() => import("src/containers/term-of-use/TermOfUsePage"))} />
                            <Route exact path="/privacy-policy" component={React.lazy(() => import("src/containers/privacy-policy/PrivacyPolicyPage"))} />

                            <Route path={["/booking-form/*"]}>
                                <BookingFormProvider>
                                    <Switch>
                                        <Route exact path="/booking-form/car-services" component={React.lazy(() => import("src/containers/booking-form/BookingFormCarServicePage"))} />
                                        <Route exact path="/booking-form/information" component={React.lazy(() => import("src/containers/booking-form/BookingFormInformationPage"))} />
                                        {notFound}
                                    </Switch>
                                </BookingFormProvider>
                            </Route>

                            {notFound}
                        </Switch>
                    </Suspense>
                </DefaultLayout>
            </ScrollToTop>
        );
    }
}

export default withRouter(Router);
import { AxiosResponse } from "axios";
import QueryString from "query-string";

export const randomCharacter = (length: number) => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let result = "";
    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
};

export const randomString = (length: number) => {
    const characters =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let result = "";
    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
};

export const getParams = (urlParams: string) => {
    return QueryString.parse(urlParams);
};

export const naturalSorter = (as: any, bs: any) => {
    let a,
        b,
        a1,
        b1,
        i = 0,
        n,
        L,
        rx =
            /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g; /** Todo : แก้จาก regex ให้เป็น code ปกติ */
    if (as === bs) return 0;
    a = as.toLowerCase().match(rx);
    b = bs.toLowerCase().match(rx);
    L = a.length;
    while (i < L) {
        if (!b[i]) return 1;
        a1 = a[i];
        b1 = b[i++];
        if (a1 !== b1) {
            n = a1 - b1;
            if (!isNaN(n)) return n;
            return a1 > b1 ? 1 : -1;
        }
    }
    return b[i] ? -1 : 0;
};

export const messageToOpener = (message: string, target: any) => {
    if (window.opener && typeof window.opener.postMessage === "function") {
        window.opener.postMessage(message, target);
    }
};

export const saveFile = (data: any, fileName: string) => {
    const blob = new Blob([data]);
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
};

export const getClassName = (...names: (string | undefined)[]): string => {
    return names.reduce<string>((result, name, index) => {
        if (!name) {
            return result;
        }

        if (index > 0) {
            result = `${result} `;
        }

        return `${result}${name.trim()}`;
    }, "");
};

export const pxToRem = (pixels: number) => {
    return `${0.0625 * pixels}rem`;
};

export const getDistrictOptions = (
    districts: District[],
    provinceId: string
): SelectOption[] => {
    return districts.reduce<SelectOption[]>((result, item) => {
        if (item.provinceId !== provinceId) {
            return result;
        }

        return [...result, { label: item.name, value: item.id }];
    }, []);
};

export const getSubDistrictOptions = (
    subDistricts: SubDistrict[],
    districtId: string
): SelectOption[] => {
    return subDistricts.reduce<SelectOption[]>((result, item) => {
        if (item.districtId !== districtId) {
            return result;
        }

        return [
            ...result,
            {
                label: item.name,
                value: item.id,
                payload: {
                    postalCode: item.postalCode ?? "10000",
                },
            },
        ];
    }, []);
};

export const isAllowKeyboardEvents = (e: React.KeyboardEvent) => {
    const allowKeyboardKeys = [
        "backspace",
        "delete",
        "arrowleft",
        "arrowright",
        "home",
        "end",
        "tab",
    ].includes(e.key.toLowerCase());

    const allowCtrlCombinationKeys =
        e.ctrlKey && ["a", "c", "v", "z"].includes(e.key.toLowerCase());

    return allowKeyboardKeys || allowCtrlCombinationKeys;
};

export const getPaginationFromHeader = (
    response: AxiosResponse,
    size: number
): Pagination => {
    return {
        size,
        pages: parseInt(response.headers["x-pagination-pages"] ?? "0"),
        total: parseInt(response.headers["x-pagination-total"] ?? "0"),
    };
};

export const findProvince = (value: string, provinces: Province[]) => {
    return provinces.find((item) => item.id === value)?.name;
};

export const findDistrict = (value: string, districts: District[]) => {
    return districts.find((item) => item.id === value)?.name;
};

export const findSubDistrict = (
    value: string,
    districtId: string,
    subDistricts: SubDistrict[]
) => {
    return subDistricts.find(
        (item) => item.districtId === districtId && item.id === value
    )?.name;
};

export const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const result = reader.result as string;
            resolve(result);
        });
        reader.readAsDataURL(file);
    });
};

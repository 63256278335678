import { useEffect } from "react";

export const useMessengerPlugin = ({ pageId, attribution }) => {
    useEffect(() => {
        const init = () => {
            const chatbox = document.getElementById("fb-customer-chat");
            chatbox?.setAttribute("page_id", pageId);
            chatbox?.setAttribute("attribution", attribution);

            window.fbAsyncInit = function () {
                FB.init({
                    xfbml: true,
                    version: "v14.0",
                });
            };

            (function (d, s, id) {
                let js = undefined;
                let fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src =
                    "https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js";
                fjs.parentNode?.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        };

        if (pageId !== undefined) {
            init();
        }
    }, [pageId, attribution]);

    return null;
};

const styles = {
    fontColorWhite: "#FFFFFF",
    fontColorGray: "#8593B9",
    fontColorDarkGray: "#545e78",
    fontColorLightGray: "#909CAE",
    fontColorLightGrayLv2: "#9EB0CA",
    fontColorGray500: "#6B7280",
    fontColorGray700: "#374151",
    fontColorBlack: "#011A40",
    fontColorYellow: "#E49E34",
    fontDisabledGray: "#D6D6D6",
    borderDisabledGray: "#C5CFDF",
    borderNormalBlue: "#A1BFEE",
    borderDarkBlue: "#35588B",
    disabledBlue: "#C1CEE2",
    colorBlue: "#3170CE",
    colorLightBlue: "#5498FF",
    colorBlueLink: "#135CD0",
    colorBlueLinkHover: "#52A2FF",
    colorBlueLinkDisabled: "#AEB5BD",
    colorRed: "#CB0001",
    colorRedHover: "#FF6666",
    colorBackgroundGray: "#EEF1F4",
    colorBackgroundDarkBlue: "#274065",
    colorBackgroundDisabled: "#B8B8B8",
    zIndexLayoutNavbar: 10,
};

export default styles;

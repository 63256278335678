/* eslint-disable no-console */
import { ReducerState, useCallback, useReducer } from "react";
import dayjs from "dayjs";
import environmentConfig from "src/configs/environment.config";

const getCurrentTimeFormatted = () => {
    return dayjs().format("HH:mm:ss.SSS");
};
const useLogger = <R extends Reducer<any, any>>(reducer: R) => {
    const reducerWithLogger = useCallback(
        (state: any, action: any) => {
            const next = reducer(state, action);

            console.groupCollapsed(
                `%cAction: %c${action.type} %cat ${getCurrentTimeFormatted()}`,
                "color: green; font-weight: bold;",
                "color: black; font-weight: bold;",
                "color: blue; font-weight: lighter;"
            );
            console.log(
                "%cPrevious State:",
                "color: #9E9E9E; font-weight: 700;",
                state
            );
            console.log(
                "%cAction:",
                "color: #00A7F7; font-weight: 700;",
                action
            );
            console.log(
                "%cNext State:",
                "color: #47B04B; font-weight: 700;",
                next
            );
            console.groupEnd();
            return next;
        },
        [reducer]
    );

    return reducerWithLogger;
};

const useReducerWithLogger = <R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>,
    initializer?: undefined
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
    if (environmentConfig.ENABLE_LOGGER) {
        /* eslint-disable-next-line react-hooks/rules-of-hooks */
        return useReducer(useLogger(reducer), initialState, initializer);
    } else {
        /* eslint-disable-next-line react-hooks/rules-of-hooks */
        return useReducer(reducer, initialState, initializer);
    }
};

export default useReducerWithLogger;

import { AxiosRequestConfig } from "axios";
import { apiInstance } from "src/configs/axios.config";
import { axiosHandler } from "src/helpers/axios.helper";

const v1 = "/v1/bookings";

export const createBooking = (
    body: CreateBookingBody,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<CreateBookingResponse>(
        apiInstance.post(v1, body, configs),
        handlerOptions
    );

export const fetchDeliveryLocations = (
    params?: FetchDeliveryLocationsParams,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<FetchDeliveryLocationsResponse>(
        apiInstance.get(`${v1}/delivery/locations`, { ...configs, params }),
        handlerOptions
    );

export const fetchDeliveryLocation = (
    id: number,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<FetchDeliveryLocationResponse>(
        apiInstance.get(`${v1}/delivery/locations/${id}`, configs),
        handlerOptions
    );

export const getDeliveryPrice = (
    params: GetDeliveryPriceParams,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<GetDeliveryPriceResponse>(
        apiInstance.get(`${v1}/delivery/price`, {
            ...configs,
            params,
        }),
        handlerOptions
    );

export const getBookingResult = (
    bookingId: string,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<BookingResultResponse>(
        apiInstance.get(`${v1}/${bookingId}/result`, configs),
        handlerOptions
    );

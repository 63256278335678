import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

// Contexts
import { OffsetLayoutContext } from "src/contexts/layout.context";

// Components
import DefaultHeader from "src/components/layout/default/DefaultHeader";
import DefaultNavBar from "src/components/layout/default/DefaultNavBar";
import DefaultFooter from "src/components/layout/default/DefaultFooter";
import styles from "src/styles";

type Props = {
    children: any;
};

const offsetLayout: OffsetLayout = {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
    xxl: 2,
};

const DefaultLayout = ({ children }: Props) => {
    return (
        <DivStyled className="default-layout">
            <OffsetLayoutContext.Provider value={offsetLayout}>
                <Row>
                    <Col xs={0} lg={24}>
                        <DefaultHeader />
                    </Col>
                </Row>
                <DefaultNavBar />
                <div className="default-content">
                    <div className="content">{children}</div>
                </div>
                <DefaultFooter />
            </OffsetLayoutContext.Provider>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.default-layout {
        min-height: 100vh;

        @media screen and (min-width: 1601px) {
            margin: 0 auto;
            max-width: 1600px;
        }

        > .default-navbar {
            z-index: ${styles.zIndexLayoutNavbar};

            @media screen and (min-width: 1601px) {
                margin: 0 auto;
                max-width: 1600px;
            }
        }

        .default-content {
            padding-top: 132px;
            min-height: calc(100vh - 64px - 80px - 341px);
            background: linear-gradient(180deg, #011a40 0%, #3c557a 100%);

            @media screen and (max-width: 767px) {
                background: #011a40;
            }
        }
    }
`;

export default DefaultLayout;

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

// Constants
import { CarLocationServices } from "src/constants";

// Contexts
import { BookingFormContext } from "src/contexts/booking-form.context";

// Services
import * as bookingService from "src/services/booking.service";
import * as bookingFormService from "src/services/booking-form.service";

// Reducers
import {
    Action,
    initialState,
    reducer,
} from "src/containers/booking-form/booking-form.reducer";

// Hooks
import useReducerWithLogger from "src/hooks/reducer.hook";

// Components
import LoadingScreen from "src/components/shared/loading/LoadingScreen";
import PaymentGatewayForm from "src/components/payment/PaymentGatewayForm";

type Props = {
    children: React.ReactChild;
};

const BookingFormProvider = ({ children }: Props) => {
    const history = useHistory();
    const { search, state: locationState } =
        useLocation<BookingFormLocationState>();

    const paymentFormRef = useRef<HTMLFormElement>(null);

    const [state, dispatch] = useReducerWithLogger(reducer, initialState);
    const {
        initializing,
        carOffer,
        provinces,
        subDistricts,
        districts,
        paymentData,
    } = state;

    useEffect(() => {
        const init = async () => {
            const bookingParams = queryString.parse(search);

            const deliveryInfo =
                bookingParams.serviceType === CarLocationServices.DELIVERY
                    ? {
                          pickUpId: parseInt(
                              bookingParams.pickUpLocation as string
                          ),
                          returnId: parseInt(
                              bookingParams.returnLocation as string
                          ),
                      }
                    : undefined;

            const payload = await bookingFormService.initialBookingForm(
                deliveryInfo
            );

            if (payload === undefined) {
                history.push(`/booking${search}`);
                return;
            }

            dispatch({
                type: Action.INITIAL_FORM,
                payload: {
                    bookingParams,
                    carSearchForm:
                        bookingService.parseBookingUrlParamsToForm(
                            bookingParams
                        ),
                    carOffer: locationState.carOffer,
                    payType: locationState.payType,
                    pickUpLocation: locationState.pickUpLocation,
                    returnLocation: locationState.returnLocation,
                    ...payload,
                },
            });
        };

        if (initializing && locationState === undefined) {
            history.push(`/booking${search}`);
            return;
        } else if (initializing) {
            init();
        }
    }, [history, search, initializing, locationState, dispatch]);

    const onPaymentFormReady = useCallback(() => {
        paymentFormRef.current?.submit();
    }, []);

    const onSubmitDiscount = useCallback(
        async (code: string) => {
            dispatch({
                type: Action.APPLYING_DISCOUNT,
            });

            const { success, payload, error } =
                await bookingFormService.verifyPromotionCode(
                    code,
                    carOffer as CarOffer
                );

            dispatch({
                type: Action.APPLIED_DISCOUNT,
                payload: payload as BookingCoupon,
            });

            if (!success) {
                return error?.error_message;
            }
        },
        [carOffer, dispatch]
    );

    const onCancelDiscount = useCallback(() => {
        dispatch({
            type: Action.CLEAR_DISCOUNT,
        });
    }, [dispatch]);

    const onSubmitCarServices = useCallback(
        (payload: CarServicesData) => {
            const deliveryInfo = state.deliveryInfo
                ? ({
                      ...state.deliveryInfo,
                      ...payload.deliveryInfo,
                      delivery: {
                          ...state.deliveryInfo.delivery,
                          ...payload.deliveryInfo?.delivery,
                      },
                      pickUp: {
                          ...state.deliveryInfo.pickUp,
                          ...payload.deliveryInfo?.pickUp,
                      },
                  } as BookingDeliveryFormData)
                : undefined;

            dispatch({
                type: Action.SUBMIT_CAR_SERVICES,
                payload: {
                    ...payload,
                    deliveryInfo,
                },
            });
            history.push(`/booking-form/information${search}`);
        },
        [history, state.deliveryInfo, search, dispatch]
    );

    const onBackToCarServices = useCallback(
        (payload: InformationData) => {
            dispatch({
                type: Action.BACK_TO_CAR_SERVICES,
                payload,
            });
        },
        [dispatch]
    );

    const onSubmitInformation = useCallback(
        (payload: InformationData) => {
            dispatch({
                type: Action.SUBMIT_INFORMATION,
                payload,
            });
        },
        [dispatch]
    );

    const onSubmitBooking = useCallback(async () => {
        dispatch({
            type: Action.SUBMITTING_BOOKING,
        });

        const result = await bookingFormService.submitBooking(state, {
            provinces: provinces as Province[],
            subDistricts: subDistricts as SubDistrict[],
            districts: districts as District[],
        });

        if (result.errorCode) {
            dispatch({
                type: Action.SUBMIT_BOOKING_FAILED,
            });
        } else {
            dispatch({
                type: Action.SUBMIT_BOOKING_SUCCESS,
                payload: result.paymentData as PaymentFormData,
            });
        }

        return result;
    }, [provinces, subDistricts, districts, state, dispatch]);

    const defaultDeliveryOptions = useMemo(() => {
        const pickUp = state.deliveryInfo?.pickUp;
        const delivery = state.deliveryInfo?.delivery;

        const pickUpOptions = pickUp
            ? [bookingService.transformAddressToOption(pickUp as SearchAddress)]
            : undefined;
        const returnOptions = delivery
            ? [
                  bookingService.transformAddressToOption(
                      delivery as SearchAddress
                  ),
              ]
            : undefined;

        return {
            pickUp: pickUpOptions,
            return: returnOptions,
        };
    }, [state.deliveryInfo?.delivery, state.deliveryInfo?.pickUp]);

    if (initializing) {
        return <LoadingScreen />;
    }

    return (
        <BookingFormContext.Provider
            value={{
                ...state,
                defaultDeliveryOptions,
                onSubmitDiscount,
                onCancelDiscount,
                onSubmitCarServices,
                onBackToCarServices,
                onSubmitInformation,
                onSubmitBooking,
            }}
        >
            {children}
            {paymentData && (
                <PaymentGatewayForm
                    ref={paymentFormRef}
                    formValue={paymentData}
                    onReady={onPaymentFormReady}
                />
            )}
        </BookingFormContext.Provider>
    );
};

export default BookingFormProvider;

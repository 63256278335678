// Helpers
import { getDefaultLocale } from "src/helpers/locale.helper";

export enum Action {
    CHANGE_LOCALE = "CHANGE_LOCALE"
}

type State = {
    locale: Locale
}

type ReducerAction =
    | { type: Action.CHANGE_LOCALE, payload: Locale }

export const initialState: State = {
    locale: getDefaultLocale()
};

export const reducer = (state: State, action: ReducerAction) => {
    switch (action.type) {
        case Action.CHANGE_LOCALE:
            return {
                ...state,
                locale: action.payload
            };
        default:
            throw new Error()
    }
};
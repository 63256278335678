import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

// Components
import DefaultDesktopNavbar from "src/components/layout/default/DefaultDesktopNavbar";
import DefaultMobileNavbar from "src/components/layout/default/DefaultMobileNavbar";

export const RENT_CAR_LINK = {
    key: "rentCars",
    path: "/",
    text: "navbar.rentCars",
};

const LINKS = [
    {
        key: "promotions",
        text: "navbar.promotions",
    },
    // {
    //     key: "our-services",
    //     text: "navbar.ourServices",
    // },
    {
        key: "about-us",
        text: "navbar.aboutUs",
    },
    {
        key: "long-term",
        text: "navbar.rentForCompany",
    },
    // {
    //     key: "used-cars",
    //     text: "navbar.usedCars",
    // },
    // {
    //     key: "careers",
    //     text: "navbar.careers",
    // },
    {
        key: "contact-us",
        text: "navbar.contactUs",
    },
];

const DefaultNavBar = () => {
    return (
        <DivStyled className="default-navbar">
            <Row>
                <Col xs={24} lg={0} className="mobile">
                    <DefaultMobileNavbar links={[RENT_CAR_LINK, ...LINKS]} />
                </Col>
                <Col xs={0} lg={24} className="desktop">
                    <DefaultDesktopNavbar links={LINKS} />
                </Col>
            </Row>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.default-navbar {
        position: absolute;
        width: 100%;
    }
`;

export default DefaultNavBar;

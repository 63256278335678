import React from 'react';
import styles from "src/styles";
import styled from "styled-components";

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const SocialButton = ({ children, ...props }: Props) => {
    return (
        <Styled className="social-button" {...props}>
            {children}
        </Styled>
    );
};

const Styled = styled.a`
    &.social-button {
        svg {
            cursor: pointer;

            &:hover path {
                fill: ${styles.colorBlue};
            }
        }
    }
`;

export default SocialButton;
// Constants
import { BOOKING_REF_NO } from "src/constants";

export const popBookingRef = () => {
    const refNo = localStorage.getItem(BOOKING_REF_NO);
    localStorage.removeItem(BOOKING_REF_NO);

    return refNo;
};

export const updateBookingRef = (refNo: string) => {
    localStorage.setItem(BOOKING_REF_NO, refNo);
};

import React, { useContext } from 'react';
import { ConfigProvider } from "antd";
import { IntlProvider } from 'react-intl';
import localeEn from 'antd/es/locale/en_US';
import localeTh from 'antd/es/locale/th_TH';

// Contents
import { AppContext } from "src/contexts/app.context";

// Components
import RouterComponent from 'src/components/router/Router';
import PDPAModal from "src/containers/app/PDPAModal";

// i18n
import message_en from 'src/i18n/en.json';
import message_th from 'src/i18n/th.json';

const getConfigLocale = (localeValue) => {
    const config = {
        en: localeEn,
        th: localeTh
    };

    return config[localeValue];
};

const getMessages = (localeValue) => {
    const messages = {
        en: message_en,
        th: message_th
    };

    return messages[localeValue];
};

const AppPage = () => {
    const { locale } = useContext(AppContext);
    const { value: localeValue } = locale;

    return (
        <ConfigProvider locale={getConfigLocale(localeValue)}>
            <IntlProvider key={localeValue} locale={localeValue} messages={getMessages(localeValue)}>
                <RouterComponent />
                <PDPAModal />
            </IntlProvider>
        </ConfigProvider>
    );
};

export default AppPage;
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";

// Constants
import { AH_ACCESS_TOKEN, AH_LOCALE } from "src/constants";

// Helpers
import { notification } from "src/helpers/notification.helper";

export const setInstanceToken = (instance: AxiosInstance, token: string) => {
    instance.defaults.headers.common[AH_ACCESS_TOKEN] = token;
};

export const setInstanceLocale = (instance: AxiosInstance, locale: string) => {
    instance.defaults.headers.common[AH_LOCALE] = locale;
};

type ErrorData = {
    error_code: number;
    error_message: string;
};

export type AxiosCustomResponse<R = any, T = ErrorData> = {
    payload?: R;
    error?: T;
    success: boolean;
    request?: any;
    response?: AxiosResponse;
    config?: AxiosRequestConfig;
    isCancelled?: boolean;
};

const getSuccessResponse = <R>(
    response: AxiosResponse
): AxiosCustomResponse<R> => {
    const { config, data, request } = response;
    return {
        payload: data,
        success: true,
        response,
        request,
        config,
    };
};

const getErrorResponse = <R>(
    error: AxiosError
): AxiosCustomResponse<R, ErrorData> => {
    const { config, response, request } = error;
    return {
        error: response?.data,
        success: false,
        isCancelled: axios.isCancel(error),
        response,
        request,
        config,
    };
};

export const axiosHandler = <T = any>(
    axiosFunc: Promise<T>,
    handlerOptions: HandlerOptions = {}
) => {
    const { notiOnError = true } = handlerOptions;

    return axiosFunc
        .then((res) => getSuccessResponse(res as any) as AxiosCustomResponse<T>)
        .catch((error) => {
            const response = getErrorResponse<T>(error);

            if (!response.isCancelled && notiOnError) {
                const message = response.error?.error_message;
                if (message) {
                    notification({
                        type: "error",
                        message,
                    });
                }
            }

            return response;
        });
};

import dayjs, { Dayjs } from 'dayjs';

export const getDateTimeRangeText = (ts1: number, ts2: number, dateFormat = 'DD/MM/YYYY', timeFormat = 'HH:mm') => {
    const dateTime1 = dayjs(ts1);
    const date1 = dateTime1.clone().startOf('day');
    const dateTime2 = dayjs(ts2);
    const date2 = dateTime2.clone().startOf('day');

    const dateTimeFormat = `${dateFormat} ${timeFormat}`;

    if (date1.isSame(date2)) {
        return `${dateTime1.format(dateTimeFormat)} - ${dateTime2.format(timeFormat)}`;
    } else {
        return `${dateTime1.format(dateTimeFormat)} - ${dateTime2.format(dateTimeFormat)}`;
    }
};

export const isSameDate = (date1: Dayjs, date2: Dayjs) => {
    if (date1 === undefined && date2 === undefined) {
        return true;
    } else if (date1 === undefined) {
        return false;
    } else if (date2 === undefined) {
        return false;
    } else {
        const value1 = date1.clone().startOf('date');
        const value2 = date2.clone().startOf('date');

        return value1.isSame(value2);
    }
};

export const mergeDateTime = (date: Dayjs, timeStr: string, timeSeperator: string = ".") => {
    const [hour, minute] = timeStr.split(timeSeperator);

    return date.clone().set("hour", parseInt(hour)).set("minute", parseInt(minute)).startOf("minute");
};
import { AxiosRequestConfig } from "axios";
import { apiInstance } from "src/configs/axios.config";
import { axiosHandler } from "src/helpers/axios.helper";

const v1 = "/v1/promotions";

export const fetchPromotions = (
    params: FetchPromotionsRequestParams,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<PromotionResponse[]>(
        apiInstance.get(`${v1}/rental-cars`, { ...configs, params }),
        handlerOptions
    );

export const fetchPromotion = (
    id: number,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<PromotionResponse>(
        apiInstance.get(`${v1}/${id}/rental-cars`, configs),
        handlerOptions
    );

export const isPromotionAvailable = (
    code: string,
    carSpecsId: number,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<IsPromotionAvailableResponse>(
        apiInstance.get(
            `${v1}/code/${code}/cars/${carSpecsId}/available`,
            configs
        ),
        handlerOptions
    );

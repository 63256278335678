import { AxiosRequestConfig } from "axios";
import { apiInstance } from "src/configs/axios.config";
import { axiosHandler } from "src/helpers/axios.helper";

const v1 = "/v1/rental-cars/specs";

export const fetchCarSpecsAccessories = (
    params: FetchCarSpecsAccessoriesRequestParams,
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<FetchCarSpecsAccessoriesResponse>(
        apiInstance.get(`${v1}/accessories`, { ...configs, params }),
        handlerOptions
    );

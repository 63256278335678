import { AxiosRequestConfig } from "axios";
import { apiInstance } from "src/configs/axios.config";
import { axiosHandler } from "src/helpers/axios.helper";

const v1 = "/v1/branches";

export const fetchBranches = (
    configs: AxiosRequestConfig = {},
    handlerOptions?: HandlerOptions
) =>
    axiosHandler<BranchResponse[]>(
        apiInstance.get(v1, configs),
        handlerOptions
    );

/* eslint-disable */
import { LOCALE } from "src/constants";

const { globalConfig = {} } = window as CustomizedWindow;

const {
    REACT_APP_AUTHORIZE_SERVER,
    REACT_APP_API_SERVER,
    REACT_APP_API_DOC_SERVER,
    REACT_APP_API_ORG_SERVER,
    REACT_APP_API_RESOURCE,
    REACT_APP_ENABLE_LOGGER,
    REACT_APP_DEFAULT_TIMEZONE,
    REACT_APP_LOCALE,
    REACT_APP_NOTIFICATION_DURATION,
    REACT_APP_VERSION,
    REACT_APP_FACEBOOK = "https://www.facebook.com/thaiprestigerentacar",
    REACT_APP_TWITTER = "https://www.twitter.com/",
    REACT_APP_YOUTUBE = "https://www.youtube.com/",
    REACT_APP_FACEBOOK_PAGE_ID,
    REACT_APP_FACEBOOK_ATTRIBUTION,
    REACT_APP_PAYMENT_GATEWAY_PAY_URL,
} = process.env;

const {
    API_RESOURCE = REACT_APP_API_RESOURCE,
    API_SERVER = REACT_APP_API_SERVER,
    API_DOC_SERVER = REACT_APP_API_DOC_SERVER,
    API_ORG_SERVER = REACT_APP_API_ORG_SERVER,
    AUTHORIZE_SERVER = REACT_APP_AUTHORIZE_SERVER,
} = globalConfig;

export default {
    API_RESOURCE,
    API_SERVER,
    API_DOC_SERVER,
    API_ORG_SERVER,
    AUTHORIZE_SERVER,
    VERSION: REACT_APP_VERSION,
    NOTIFICATION_DURATION: REACT_APP_NOTIFICATION_DURATION ?? 5,
    LOCALE: REACT_APP_LOCALE ?? LOCALE.TH,
    DEFAULT_TIMEZONE: REACT_APP_DEFAULT_TIMEZONE,
    DEFAULT_PAGE_AFTER_AUTH: "/",
    ENABLE_LOGGER: REACT_APP_ENABLE_LOGGER === "true" ? true : false,
    SOCIAL_FACEBOOK: REACT_APP_FACEBOOK,
    SOCIAL_TWITTER: REACT_APP_TWITTER,
    SOCIAL_YOUTUBE: REACT_APP_YOUTUBE,
    FACEBOOK_PAGE_ID: REACT_APP_FACEBOOK_PAGE_ID,
    FACEBOOK_ATTRIBUTION: REACT_APP_FACEBOOK_ATTRIBUTION,
    PAYMENT_GATEWAY_PAY_URL: REACT_APP_PAYMENT_GATEWAY_PAY_URL,
    UPLOAD_MAX_SIZE: 5 * 1024 * 1024,
};

import dayjs from "dayjs";

// Configs
import {
    apiDocInstance,
    apiInstance,
    apiOrgInstance,
} from "src/configs/axios.config";
import environmentConfig from "src/configs/environment.config";

// Constants
import { LOCALE, LS_LOCALE } from "src/constants";

// Helpers
import * as LocaleHelper from "src/helpers/locale.helper";
import * as AxiosHelper from "src/helpers/axios.helper";

export const setLocale = (locale: string) => {
    localStorage.setItem(LS_LOCALE, locale);
    AxiosHelper.setInstanceLocale(apiInstance, locale);
    AxiosHelper.setInstanceLocale(apiDocInstance, locale);
    AxiosHelper.setInstanceLocale(apiOrgInstance, locale);
    dayjs.locale(locale);
};

export const getDefaultLocale = () => {
    const locale = localStorage.getItem(LS_LOCALE);

    const key = Object.keys(LOCALE).find(
        (key) => LOCALE[key as LocaleKey].value === locale
    ) as LocaleKey;
    const localeObj = key ? LOCALE[key] : (environmentConfig.LOCALE as Locale);

    LocaleHelper.setLocale(localeObj.value);

    return localeObj;
};

export const getLocaleByKey = (value: string) => {
    const key = value.toUpperCase() as LocaleKey;
    return LOCALE[key] ? LOCALE[key] : environmentConfig.LOCALE;
};

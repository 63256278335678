import { v4 as uuidV4 } from "uuid";

const PDPA_KEYS = {
    ACCEPTED_VERSION: "pdpa_accepted_version",
    IDENTIFIER: "pdpa_identifier",
};

export const getPDPA = async (): Promise<
    PDPA & {
        isAccepted: boolean;
    }
> => {
    // TODO: Call endpoint get latest version
    const latestVersion = "1";

    const acceptedVersion = localStorage.getItem(PDPA_KEYS.ACCEPTED_VERSION);
    const identifier = localStorage.getItem(PDPA_KEYS.IDENTIFIER) ?? uuidV4();

    return {
        isAccepted: acceptedVersion === latestVersion,
        currentVersion: latestVersion,
        identifier,
    };
};

export const acceptPDPA = async ({ currentVersion, identifier }: PDPA) => {
    if (!currentVersion || !identifier) {
        return;
    }

    // TODO: Call endpoint accept pdpa

    localStorage.setItem(PDPA_KEYS.ACCEPTED_VERSION, currentVersion);
    localStorage.setItem(PDPA_KEYS.IDENTIFIER, identifier);
};

import React, { useContext } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

// Contexts
import { OffsetLayoutContext } from "src/contexts/layout.context";

// Helpers
import { calculateLayout } from "src/helpers/ui.helper";

// Components
import DefaultDesktopFooter from "src/components/layout/default/DefaultDesktopFooter";
import DefaultMobileFooter from "src/components/layout/default/DefaultMobileFooter";

const DefaultFooter = () => {
    const offsetLayout = useContext(OffsetLayoutContext);
    const layout = calculateLayout(offsetLayout, 24);

    return (
        <DivStyled className="default-footer">
            <Row>
                <Col {...layout}>
                    <Row>
                        <Col xs={24} md={0}>
                            <DefaultMobileFooter />
                        </Col>
                        <Col xs={0} md={24}>
                            <DefaultDesktopFooter />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.default-footer {
        background: #031630;
        min-height: 200px;
    }
`;

export default DefaultFooter;

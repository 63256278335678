import React, { useContext, useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import throttle from "lodash/throttle";
import styles from "src/styles";

// Contexts
import { OffsetLayoutContext } from "src/contexts/layout.context";

// Helpers
import { calculateLayout } from "src/helpers/ui.helper";

// Components
import LocaleButton from "src/components/shared/button/LocalesButton";
import Contact from "src/components/shared/common/Contact";

// Assets
import logo from "src/assets/images/web_logo_wo_margin.png";

type Props = {
    links: {
        key: string;
        path?: string;
        text: string;
    }[];
};

const DefaultMobileNavbar = ({ links = [] }: Props) => {
    const offsetLayout = useContext(OffsetLayoutContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const layout = calculateLayout(offsetLayout, 24);

    useEffect(() => {
        const handleResize = throttle(() => {
            if (window.innerWidth >= 992 && isOpen) {
                setIsOpen(false);
            }
        }, 20);

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isOpen]);

    const handleClickLink = () => {
        setIsOpen(false);
    };

    return (
        <DivStyled className="default-mobile-navbar">
            <Row>
                <Col {...layout}>
                    <Row>
                        <Col span={2} className="navbar">
                            <MenuOutlined onClick={() => setIsOpen(true)} />
                        </Col>
                        <Col span={20} className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ModalStyled
                className="mobile-navbar-modal"
                centered={false}
                footer={false}
                visible={isOpen}
                closable={false}
                closeIcon={false}
            >
                <Row>
                    <Col span={24} className="header">
                        <div className="logo">
                            <Link to="/" onClick={handleClickLink}>
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div
                            className="close-button"
                            onClick={() => setIsOpen(false)}
                        >
                            <svg
                                width="23"
                                height="23"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10.811 11.5003L0.143061 22.1676C-0.0476894 22.3584 -0.0476894 22.6669 0.143061 22.8577C0.333809 23.0474 0.642307 23.0474 0.832092 22.8577L11.5 12.1892L22.1679 22.8577C22.3577 23.0474 22.6672 23.0474 22.8569 22.8577C23.0477 22.6669 23.0477 22.3584 22.8569 22.1676L12.189 11.5003L22.8569 0.83186C23.0477 0.6411 23.0477 0.332588 22.8569 0.142794C22.7612 0.0470123 22.6375 0 22.512 0C22.3874 0 22.2629 0.0470085 22.1681 0.142794L11.5001 10.8113L0.832239 0.142794C0.737427 0.0470123 0.61277 0 0.488279 0C0.363625 0 0.238171 0.0470085 0.143356 0.142794C-0.0473919 0.333553 -0.0473919 0.642065 0.143356 0.83186L10.811 11.5003Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </Col>
                </Row>
                <div className="body">
                    <Row gutter={[0, 40]} className="content">
                        {links.map(({ key, text, path }) => (
                            <Col span={24} key={key}>
                                <Link
                                    to={path ?? `/${key}`}
                                    onClick={handleClickLink}
                                >
                                    <FormattedMessage id={text} />
                                </Link>
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={[0, 50]} className="footer">
                        {
                            <Col span={24}>
                                <LocaleButton />
                            </Col>
                        }
                        <Col span={24}>
                            <Contact />
                        </Col>
                    </Row>
                </div>
            </ModalStyled>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.default-mobile-navbar {
        background: linear-gradient(
            180deg,
            #011a40 51.5%,
            rgba(1, 17, 64, 0) 81.39%
        );
        height: 150px;
        padding-top: 20px;

        .navbar {
            display: flex;
            align-items: center;

            .anticon {
                font-size: 20px;
                color: #92a6c4;
            }
        }

        .logo {
            text-align: center;

            img {
                height: 40px;
            }
        }
    }
`;

const ModalStyled = styled(Modal)`
    &.mobile-navbar-modal {
        width: 100vw !important;
        max-width: unset;
        margin: 0;
        padding: 0;
        top: 0;
        height: 100%;
        background: #011a40;

        .ant-modal-content {
            box-shadow: none;
        }

        .ant-modal-body {
            height: 100vh;
            background: #011a40;
            padding: 40px;
            line-height: 1.6;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 50px;

            .logo {
                img {
                    height: 40px;
                }
            }

            .close-button {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .body {
            overflow-y: auto;
            height: calc(100vh - 170px);

            .content {
                a {
                    color: ${styles.fontColorWhite};
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                }
            }

            .footer {
                margin-top: 50px;
            }
        }
    }
`;

export default DefaultMobileNavbar;

import React, { useCallback, useMemo } from 'react';
import { AppContext } from 'src/contexts/app.context';
import { Action, reducer, initialState } from 'src/contexts/app.reducer';
import * as localeHelper from 'src/helpers/locale.helper';
import useReducerWithLogger from 'src/hooks/reducer.hook';

type Props = {
    children: any
}

const AppProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducerWithLogger(reducer, initialState);
    const { locale } = state

    const setLocale = useCallback((locale: Locale) => {
        localeHelper.setLocale(locale.value);
        return dispatch({ type: Action.CHANGE_LOCALE, payload: locale });
    }, [dispatch])

    const appValue = useMemo(() => ({
        locale,
        setLocale,
    }), [locale, setLocale]);

    return (
        <AppContext.Provider value={appValue}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
import { Dayjs } from "dayjs";
import Decimal from "decimal.js";
import { IntlShape } from "react-intl";

// APIs
import * as rentalCarSpecsAPI from "src/apis/rental-cars/specs.api";
import * as bookingAPI from "src/apis/booking.api";
import * as masterAPI from "src/apis/doc/master.api";
import * as promotionsAPI from "src/apis/promotions.api";

// Constants
import {
    BookingPayType,
    CAR_SERVIES_METHOD,
    IdentifierType,
    TaxType,
} from "src/constants";

// Helpers
import { AxiosCustomResponse } from "src/helpers/axios.helper";
import {
    findDistrict,
    findProvince,
    findSubDistrict,
} from "src/helpers/common.helper";

// Services
import {
    fetchAddress,
    fetchCarServiceLocationOptions,
    getCarSearchFormValues,
} from "src/services/booking.service";
import * as paymentService from "src/services/payment.service";

// Assets
import accessory1 from "src/assets/mock_images/accessory_1.svg";
import countries from "src/assets/mock_jsons/countries.json";

// Components
import { CarSearchForm } from "src/components/booking/CarSearchCard";

export const fetchBookingAccessories = async (
    carSpecsId: number
): Promise<AxiosCustomResponse<Accessory[]>> => {
    const { payload = [] } = await rentalCarSpecsAPI.fetchCarSpecsAccessories({
        car_specs_id: carSpecsId,
        limit: 100,
    });

    return {
        payload: _transformBookingAccessories(payload),
        success: true,
    };
};

const _transformBookingAccessories = (
    payload: FetchCarSpecsAccessoriesResponse
): Accessory[] => {
    return payload.reduce<Accessory[]>((result, item) => {
        const {
            accessories_id: accessoryId,
            amount,
            name,
            on_hand: onHand,
            quota,
            description,
        } = item;

        if (onHand === 0) {
            return result;
        }

        return [
            ...result,
            {
                accessoryId,
                imageUrl: accessory1,
                name,
                description,
                maxQuantity: quota > onHand ? onHand : quota,
                price: parseFloat(amount),
            },
        ];
    }, []);
};

export const initialCarServicesPage = async (
    carSpecsId: number
): Promise<{
    accessories: Accessory[];
}> => {
    const [accessoriesResponse] = await Promise.all([
        fetchBookingAccessories(carSpecsId),
    ]);

    return {
        accessories: accessoriesResponse.success
            ? (accessoriesResponse.payload as Accessory[])
            : [],
    };
};

export const getDateTimeString = (date: Dayjs) => {
    return `${date.format("DD/MM/YYYY")} at ${date.format("HH:mm")}`;
};

export const getPrice = (
    payType: BookingPayType,
    { payNow, payOnCounter, specialDeal }: BookingPriceOffer
) => {
    if (payType === BookingPayType.PAY_AT_COUTNER) {
        return payOnCounter;
    } else {
        return specialDeal ?? payNow;
    }
};

export const verifyPromotionCode = async (
    code: string,
    carOffer: CarOffer
): Promise<AxiosCustomResponse<BookingCoupon>> => {
    const { success, payload, error } =
        await promotionsAPI.isPromotionAvailable(
            code,
            carOffer.carSpecsId,
            undefined,
            { notiOnError: false }
        );

    if (!success || error) {
        return {
            success: false,
            error,
        };
    }
    const result = payload as IsPromotionAvailableResponse;

    return {
        success: true,
        payload: {
            promotionCode: result.promotion_code,
            amount: parseFloat(result.amount),
        },
    };
};

const calculateDiscount = (
    intl: IntlShape,
    sumPrice: Decimal,
    coupon?: BookingCoupon
): BookingPrice | undefined => {
    if (coupon === undefined) {
        return undefined;
    }

    const result: BookingPrice = {
        key: "discount",
        type: "discount",
        name: intl.formatMessage({ id: "discount" }),
        description: intl.formatMessage(
            { id: "promoCode" },
            { code: coupon?.promotionCode }
        ),
        price: 0,
    };

    if (coupon.amount !== undefined) {
        return {
            ...result,
            price: new Decimal(coupon.amount).mul(-1),
        };
    } else if (coupon.percent !== undefined) {
        const percent = new Decimal(coupon.percent).dividedBy(100);
        return {
            ...result,
            price: sumPrice.mul(percent).mul(-1),
        };
    } else {
        return undefined;
    }
};

export const calculatePrices = (
    intl: IntlShape,
    taxType: TaxType = TaxType.PERSONAL,
    carOffer: CarOffer,
    numberOfDays: number,
    payType: BookingPayType,
    bookingAccessories: BookingAccessory[] = [],
    coupon?: BookingCoupon,
    deliveryCost?: number
): { netPrice: Decimal; list: BookingPrice[] } => {
    const rentPrice = new Decimal(
        getPrice(payType, carOffer.offers) * numberOfDays
    );

    const accessoryPrices = bookingAccessories.map<BookingPrice>(
        ({ id, name, description, price, quantity }) => {
            return {
                key: `accessory_${id}`,
                type: "accessory",
                name: name,
                description,
                price: new Decimal(price)
                    .mul(new Decimal(quantity))
                    .mul(numberOfDays),
            };
        }
    );

    let result: BookingPrice[] = [
        {
            key: "rentPrice",
            type: "rentCar",
            name: intl.formatMessage({ id: "rentCarPrice" }),
            description: `${carOffer.brand} ${carOffer.size}`,
            price: rentPrice,
        },
    ];

    if (deliveryCost !== undefined) {
        result.push({
            key: "deliveryPrice",
            name: intl.formatMessage({ id: "deliveryPrice" }),
            price: new Decimal(deliveryCost),
            type: "delivery",
        });
    }

    if (accessoryPrices.length) {
        result = [...result, ...accessoryPrices];
    }

    let sumPrice = result.reduce<Decimal>(
        (result, item) => result.plus(item.price),
        new Decimal(0)
    );

    const discount = calculateDiscount(intl, sumPrice, coupon);
    if (discount) {
        sumPrice = sumPrice.plus(discount?.price ?? 0);
        result.push(discount);
    }

    const vat = sumPrice.mul(new Decimal(0.07));

    if (taxType === TaxType.COMPANY) {
        const withHoldingTax = sumPrice.mul(new Decimal(0.05)).mul(-1);
        sumPrice = sumPrice.plus(withHoldingTax);
        result.push({
            key: "withHoldingTax",
            type: "withHoldingTax",
            name: intl.formatMessage({ id: "withHoldingTax" }),
            price: withHoldingTax,
        });
    }

    result.push({
        key: "vat",
        type: "vat",
        name: intl.formatMessage({ id: "vat" }),
        price: vat,
    });

    const netPrice = sumPrice.plus(vat);

    return {
        netPrice: netPrice.toNumber() >= 0 ? netPrice : new Decimal(0),
        list: result,
    };
};

export const initialBookingForm = async (
    deliveryInfo:
        | {
              pickUpId: number;
              returnId: number;
          }
        | undefined
): Promise<
    | {
          provinces: Province[];
          districts: District[];
          subDistricts: SubDistrict[];
          locations: CarSearchLocation[];
          countries: Country[];
          deliveryInfo?: BookingDeliveryFormData;
      }
    | undefined
> => {
    const { pickUpId, returnId } = deliveryInfo ?? {};
    const deliveryAddressPromises = deliveryInfo
        ? [
              fetchAddress(pickUpId as number),
              fetchAddress(returnId as number),
              bookingAPI.getDeliveryPrice({
                  pick_up_location_id: pickUpId as number,
                  returned_location_id: returnId as number,
              }),
          ]
        : [];

    const [
        masterAddressesResponse,
        branchesResponse,
        pickUpResponse,
        returnResponse,
        deliveryPriceResponse,
    ] = await Promise.all([
        masterAPI.fetchAddresses(),
        fetchCarServiceLocationOptions(),
        ...deliveryAddressPromises,
    ]);

    const {
        province: provinceList,
        district: districtListObj,
        "sub-district": subDistrictListObj,
    } = masterAddressesResponse.payload as AddressesRespones;

    const provinces = provinceList.map<Province>(({ code: id, name }) => ({
        id,
        name,
    }));

    const districts = Object.keys(districtListObj).reduce<District[]>(
        (result, provinceId) => {
            const list = (
                districtListObj as Record<
                    string,
                    { code: string; name: string }[]
                >
            )[provinceId];

            return [
                ...result,
                ...list.map(({ code: id, name }) => ({ id, name, provinceId })),
            ];
        },
        []
    );

    const subDistricts = Object.keys(subDistrictListObj).reduce<SubDistrict[]>(
        (result, districtId) => {
            const list = (
                subDistrictListObj as Record<
                    string,
                    { code: string; name: string; postCode: string }[]
                >
            )[districtId];

            return [
                ...result,
                ...list.map(({ code: id, name, postCode: postalCode }) => ({
                    id,
                    name,
                    districtId,
                    postalCode,
                })),
            ];
        },
        []
    );

    const countryList = countries.map<Country>(({ name, dial_code, code }) => ({
        id: code,
        name: name,
        countryCode: dial_code,
    }));

    const locations = branchesResponse.success
        ? (branchesResponse.payload as CarSearchLocation[])
        : [];

    const result = {
        districts,
        provinces,
        subDistricts,
        locations,
        countries: countryList,
    };

    if (deliveryInfo === undefined) {
        return result;
    }

    return {
        ...result,
        deliveryInfo: {
            pickUpAtDeliveryAddress: pickUpId === returnId,
            pickUp: pickUpResponse.payload as SearchAddress,
            delivery: returnResponse.payload as SearchAddress,
            servicePrice: parseFloat(
                (deliveryPriceResponse.payload as GetDeliveryPriceResponse)
                    .summary_price
            ),
        },
    };
};

export const matchBookingAccessoriesWithDataSource = (
    dataSource: Accessory[],
    bookingAccessories: BookingAccessory[] = []
): BookingAccessoryFormItem[] => {
    const selectedObj = bookingAccessories.reduce<Record<string, number>>(
        (result, item) => {
            return {
                ...result,
                [item.id]: item,
            };
        },
        {}
    );

    return dataSource.reduce<BookingAccessoryFormItem[]>((result, item) => {
        const selectedItem = selectedObj[item.accessoryId];
        if (selectedItem === undefined) {
            return [
                ...result,
                {
                    value: false,
                    quantity: undefined,
                },
            ];
        }

        return [
            ...result,
            {
                value: true,
                quantity: (selectedItem as BookingAccessoryFormItem).quantity,
            },
        ];
    }, []);
};

export const submitBooking = async (
    state: BookingFormReducerState,
    { provinces = [], districts = [], subDistricts = [] }: ProvinceGroups
): Promise<SubmitBookingResult> => {
    const {
        accessories,
        carOffer,
        carSearchForm,
        deliveryInfo,
        payType,
        renterInfo,
        requireTax,
        taxInfo,
        requireFlight,
        flightInfo,
        coupon,
    } = state;

    const { success, payload, error } = await bookingAPI.createBooking(
        {
            ..._getBookingCarForm(carSearchForm, deliveryInfo),
            car_specs_id: carOffer?.carSpecsId as number,
            payment_method: payType,
            customer: _getBookingCustomer(renterInfo as BookingRenterFormData),
            tax_invoice: requireTax
                ? _getBookingTaxInvoice(taxInfo as BookingTaxFormData, {
                      provinces,
                      districts,
                      subDistricts,
                  })
                : undefined,
            accessories: _getBookingAccessories(accessories),
            flight_info: requireFlight
                ? _getBookingFlightInfo(flightInfo as BookingFlightFormData)
                : undefined,
            promotion_code: coupon?.promotionCode,
        },
        undefined,
        { notiOnError: false }
    );

    if (!success || payload === undefined) {
        return {
            errorCode: error?.error_code,
        };
    }

    const { total_price, transaction_ref, booking_id, ...paymentData } =
        payload as CreateBookingResponse;

    paymentService.updateBookingRef(booking_id);

    return {
        errorCode: error?.error_code,
        transactionRef: transaction_ref,
        paymentData: paymentData,
        bookingId: booking_id,
    };
};

const _getBookingCarForm = (
    carSearchForm: CarSearchForm,
    deliveryInfo?: BookingDeliveryFormData
) => {
    const {
        branchId: pickUpBranchId,
        returnBranchId,
        pickUpDate,
        returnDate,
    } = getCarSearchFormValues(carSearchForm);

    const carServiceMethod =
        deliveryInfo === undefined
            ? CAR_SERVIES_METHOD.SELF_PICKUP
            : CAR_SERVIES_METHOD.DELIVERY;

    const commonPayload = {
        pick_up_datetime: pickUpDate.valueOf(),
        returned_datetime: returnDate.valueOf(),
        pick_up_method: carServiceMethod,
        returned_method: carServiceMethod,
    };

    if (deliveryInfo === undefined) {
        return {
            ...commonPayload,
            pick_up_branch_id: pickUpBranchId,
            returned_branch_id: returnBranchId,
        };
    }

    const pickUpDelivery = {
        id: pickUpBranchId,
        address_1: deliveryInfo.pickUp?.address as string,
        note: deliveryInfo.pickUp?.note,
    };
    const returnDeliver = {
        id: returnBranchId,
        address_1: deliveryInfo.delivery?.address as string,
        note: deliveryInfo.delivery?.note,
    };

    return {
        ...commonPayload,
        pick_up_delivery: pickUpDelivery,
        returned_delivery: deliveryInfo.pickUpAtDeliveryAddress
            ? {
                  ...returnDeliver,
                  address_1: deliveryInfo.pickUp?.address as string,
                  note: deliveryInfo.pickUp?.note,
              }
            : returnDeliver,
    };
};

const _getBookingCustomer = (
    renterInfo: BookingRenterFormData
): CreateBookingCustomer => {
    const identifier =
        renterInfo.identifier === IdentifierType.PERSONAL_CARD
            ? (renterInfo.identifierId as string)
            : (renterInfo.passport as string);

    return {
        first_name: renterInfo.firstName as string,
        last_name: renterInfo.lastName as string,
        driving_license_id: renterInfo.drivingLicenseId as string,
        identifier_type: renterInfo.identifier,
        identifier,
        email: renterInfo.email as string,
        line_id: renterInfo.lineId,
        country_calling_code: renterInfo.countryCode as string,
        mobile_phone_number: renterInfo.phoneNo as string,
        company_name: renterInfo.companyName,
    };
};

const _getBookingTaxInvoice = (
    taxInfo: BookingTaxFormData,
    { districts, provinces, subDistricts }: ProvinceGroups
): CreateBookingTaxInvoice => {
    return {
        tax_invoice_type: taxInfo.type,
        company_name: taxInfo.companyName,
        first_name: taxInfo.firstName,
        last_name: taxInfo.lastName,
        email: taxInfo.email as string,
        country_calling_code: taxInfo.countryCode as string,
        phone_number: taxInfo.phoneNo as string,
        tax_id: taxInfo.taxId as string,
        address_1: taxInfo.address as string,
        sub_district: findSubDistrict(
            taxInfo.subDistrict as string,
            taxInfo.district as string,
            subDistricts
        ) as string,
        district: findDistrict(taxInfo.district as string, districts) as string,
        province: findProvince(taxInfo.province as string, provinces) as string,
        postal: taxInfo.postalCode as string,
        deliver_address1: taxInfo.taxAddress?.address,
        deliver_sub_district: findSubDistrict(
            taxInfo.taxAddress?.subDistrict as string,
            taxInfo.taxAddress?.district as string,
            subDistricts
        ),
        deliver_district: findDistrict(
            taxInfo.taxAddress?.district as string,
            districts
        ),
        deliver_province: findProvince(
            taxInfo.taxAddress?.province as string,
            provinces
        ) as string,
        deliver_postal: taxInfo.taxAddress?.postalCode,
    };
};

const _getBookingAccessories = (
    accessories: BookingAccessory[] = []
): CreateBookingAccessory[] => {
    return accessories.map<CreateBookingAccessory>(
        ({ id: accessories_id, quantity }) => ({
            accessories_id,
            quantity,
        })
    );
};

const _getBookingFlightInfo = (
    flightInfo: BookingFlightFormData
): CreateBookingFlightInfo => {
    return {
        airline: flightInfo.airline,
        flight_number: flightInfo.flightNo,
    };
};

export const isCouponApplied = (coupon: undefined | BookingCoupon) => {
    if (coupon === undefined) {
        return false;
    }

    return coupon.amount !== undefined || coupon.percent !== undefined;
};

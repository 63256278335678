import React, { useEffect } from "react";
import { Router } from "react-router-dom";

// Configs
import history from "src/configs/router.config";
import Config from "src/configs/environment.config";
import "src/configs/accounting.config";
import "src/configs/axios.config";
import "src/configs/dayjs.config";

// Components
import AppPage from "src/containers/app/AppPage";
import AppProvider from "src/contexts/AppProvider";

// Hooks
import { useMessengerPlugin } from "src/hooks/fbMessenger.hook";

const App = () => {
    useMessengerPlugin({
        pageId: Config.FACEBOOK_PAGE_ID,
        attribution: Config.FACEBOOK_ATTRIBUTION,
    });

    useEffect(() => {
        console.log(`App Version: ${Config.VERSION}`);
    }, []);

    return (
        <Router history={history}>
            <AppProvider>
                <AppPage />
            </AppProvider>
        </Router>
    );
};

export default App;

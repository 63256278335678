import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import styles from "src/styles";

// Constants
import { LOCALE } from "src/constants";

// Contexts
import { AppContext } from "src/contexts/app.context";

// Helpers
import { getClassName } from "src/helpers/common.helper";

const LocalesButton = () => {
    const { locale, setLocale } = useContext(AppContext);

    const locales = useMemo(() => {
        return Object.keys(LOCALE).reduce<JSX.Element[]>((result, key) => {
            const item = LOCALE[key as LocaleKey];
            const activeClass =
                locale?.value === item.value ? "active" : undefined;

            return [
                ...result,
                <div
                    key={item.value}
                    className={getClassName("locale", activeClass)}
                >
                    <span onClick={() => setLocale(item)}>{key}</span>
                </div>,
            ];
        }, []);
    }, [locale?.value, setLocale]);

    return <DivStyled className="locales-button">{locales}</DivStyled>;
};

const DivStyled = styled.div`
    &.locales-button {
        display: flex;
        align-items: center;

        .locale {
            padding: 0 14px;
            height: 16px;
            border-right: 1px solid #8593b9;
            display: flex;
            align-items: center;
            color: ${styles.fontColorGray};

            &.active {
                color: ${styles.fontColorWhite};
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border: none;
                padding-right: 0;
            }

            span {
                cursor: pointer;
                line-height: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
`;

export default LocalesButton;

import React, { Component } from 'react';
import styled from "styled-components";

// Components
import { Col, Row, Spin } from "antd";

const StyledRow = styled(Row)`
    & {
        height: calc(100vh - 32px);
        text-align: center;
    }
`;

class LoadingScreen extends Component {
    render() {
        return (
            <StyledRow justify="space-around" align="middle">
                <Col span={24}>
                    <Spin size="large" />
                </Col>
            </StyledRow>
        );
    }
}

export default LoadingScreen;
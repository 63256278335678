import axios from "axios";

// Configs
import environmentConfig from "src/configs/environment.config";

const authorizeInstance = axios.create({
    baseURL: environmentConfig.AUTHORIZE_SERVER,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

const apiInstance = axios.create({
    baseURL: environmentConfig.API_SERVER,
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

const apiDocInstance = axios.create({
    baseURL: environmentConfig.API_DOC_SERVER,
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

const apiOrgInstance = axios.create({
    baseURL: environmentConfig.API_ORG_SERVER,
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

export { authorizeInstance, apiInstance, apiDocInstance, apiOrgInstance };

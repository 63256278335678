import React, { forwardRef, useEffect } from "react";
import environmentConfig from "src/configs/environment.config";

type Props = {
    formValue?: PaymentFormData;
    onReady?: () => void;
};

const PaymentGatewayForm = forwardRef<HTMLFormElement, Props>(
    ({ formValue, onReady = () => {} }, ref) => {
        useEffect(() => {
            onReady();
        }, [onReady]);

        return (
            <form
                ref={ref}
                action={environmentConfig.PAYMENT_GATEWAY_PAY_URL}
                method="post"
            >
                <input
                    type="hidden"
                    name="access_key"
                    value={formValue?.access_key}
                />
                <input
                    type="hidden"
                    name="profile_id"
                    value={formValue?.profile_id}
                />
                <input
                    type="hidden"
                    name="transaction_uuid"
                    value={formValue?.transaction_uuid}
                />
                <input
                    type="hidden"
                    name="signed_field_names"
                    value={formValue?.signed_field_names}
                />
                <input
                    type="hidden"
                    name="unsigned_field_names"
                    value={formValue?.unsigned_field_names}
                />
                <input
                    type="hidden"
                    name="signed_date_time"
                    value={formValue?.signed_date_time}
                />
                <input type="hidden" name="locale" value={formValue?.locale} />
                <input
                    type="hidden"
                    name="transaction_type"
                    value={formValue?.transaction_type}
                />
                <input
                    type="hidden"
                    name="reference_number"
                    value={formValue?.reference_number}
                />
                <input type="hidden" name="amount" value={formValue?.amount} />
                <input
                    type="hidden"
                    name="currency"
                    value={formValue?.currency}
                />
                <input
                    type="hidden"
                    name="bill_to_address_city"
                    value={formValue?.bill_to_address_city}
                />
                <input
                    type="hidden"
                    name="bill_to_address_country"
                    value={formValue?.bill_to_address_country}
                />
                <input
                    type="hidden"
                    name="bill_to_address_line1"
                    value={formValue?.bill_to_address_line1}
                />
                <input
                    type="hidden"
                    name="bill_to_address_postal_code"
                    value={formValue?.bill_to_address_postal_code}
                />
                <input
                    type="hidden"
                    name="bill_to_email"
                    value={formValue?.bill_to_email}
                />
                <input
                    type="hidden"
                    name="bill_to_forename"
                    value={formValue?.bill_to_forename}
                />
                <input
                    type="hidden"
                    name="bill_to_surname"
                    value={formValue?.bill_to_surname}
                />
                <input
                    type="hidden"
                    name="bill_to_phone"
                    value={formValue?.bill_to_phone}
                />
                <input
                    type="hidden"
                    name="bill_to_address_state"
                    value={formValue?.bill_to_address_state}
                />
                <input
                    type="hidden"
                    name="allow_payment_token_update"
                    value={formValue?.allow_payment_token_update}
                />
                <input
                    type="hidden"
                    name="payment_method"
                    value={formValue?.payment_method}
                />
                <input
                    type="hidden"
                    name="auth_trans_ref_no"
                    value={formValue?.auth_trans_ref_no}
                />
                <input
                    type="hidden"
                    name="signature"
                    value={formValue?.signature}
                />
            </form>
        );
    }
);

export default PaymentGatewayForm;

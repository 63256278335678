import { message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { NoticeType, ArgsProps } from "antd/lib/message";
import uniqueId from "lodash/uniqueId";

// Helpers
import { getClassName } from "src/helpers/common.helper";

type NotificationOptions = {
    type: NoticeType;
    message: string;
    options?: Omit<ArgsProps, "content" | "type">;
};

export const notification = ({
    type,
    message: msg,
    options = {},
}: NotificationOptions) => {
    const key = uniqueId();
    const { duration = 5 } = options;

    message[type]({
        ...options,
        key,
        content: (
            <div className="content">
                <span>{msg}</span>
                <CloseOutlined
                    className="close-btn"
                    onClick={() => message.destroy(key)}
                />
            </div>
        ),
        className: getClassName(`custom-${type}`, options.className),
        duration,
    });
};

import React, { useContext } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import styles from "src/styles";

// Configs
import environmentConfig from "src/configs/environment.config";

// Contexts
import { OffsetLayoutContext } from "src/contexts/layout.context";

// Helpers
import { calculateLayout } from "src/helpers/ui.helper";

// Components
import SocialButton from "src/components/shared/button/SocialButton";
import Contact from "src/components/shared/common/Contact";
import LocalesButton from "src/components/shared/button/LocalesButton";

// Assets
import { ReactComponent as FacebookIcon } from "src/assets/icons/facebook.svg";

const DefaultHeader = () => {
    const offsetLayout = useContext(OffsetLayoutContext);
    const layout = calculateLayout(offsetLayout, 24);

    return (
        <DivStyled className="default-header">
            <Row>
                <Col {...layout}>
                    <Row>
                        <Col span={12}>
                            <Contact />
                        </Col>
                        <Col span={12} className="right-column">
                            <LocalesButton />
                            <div className="social-icons">
                                <SocialButton
                                    target="_blank"
                                    href={environmentConfig.SOCIAL_FACEBOOK}
                                    rel="noreferrer"
                                >
                                    <FacebookIcon />
                                </SocialButton>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </DivStyled>
    );
};

const DivStyled = styled.div`
    &.default-header {
        height: 32px;
        font-size: 16px;
        line-height: 26px;
        background: #102b54;
        color: ${styles.fontColorGray};

        .right-column {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .social-icons {
            margin-left: 32px;

            a {
                margin: 6px;
                display: flex;

                @media screen and (max-width: 575px) {
                    margin: 6px 3px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
`;

export default DefaultHeader;

import { apiInstance } from "src/configs/axios.config";
import { axiosHandler } from "src/helpers/axios.helper";

const v1 = "/v1/rental-cars/cars";

export const fetchSpecialDeals = () =>
    axiosHandler<FetchSpecialDealsResponse>(
        apiInstance.get(`${v1}/special-deal`)
    );

export const fetchOffers = (params: FetchCarsOffersRequestParams) =>
    axiosHandler<FetchCarsOffersResponse>(
        apiInstance.get(`${v1}/offers`, { params })
    );

import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import styles from "src/styles";

// Contexts
import { OffsetLayoutContext } from "src/contexts/layout.context";

// Helpers
import { calculateLayout } from "src/helpers/ui.helper";

// Assets
import logo from "src/assets/images/web_logo_wo_margin.png";
import { RENT_CAR_LINK } from "src/components/layout/default/DefaultNavBar";

type Props = {
    links: { key: string; path?: string; text: string }[];
};

const DefaultDesktopNavbar = ({ links }: Props) => {
    const offsetLayout = useContext(OffsetLayoutContext);
    const layout = calculateLayout(offsetLayout, 24);
    return (
        <DivStyled className="default-desktop-navbar">
            <Row>
                <Col {...layout}>
                    <Row>
                        <Col span={3} className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </Col>
                        <Col span={21} className="navbar">
                            {links.map(({ key, path, text }) => (
                                <Link key={key} to={path ?? `/${key}`}>
                                    <FormattedMessage id={text} />
                                </Link>
                            ))}
                            <Link
                                to={RENT_CAR_LINK.path}
                                className="rent-car-link"
                            >
                                <Button type="primary" htmlType="submit">
                                    <FormattedMessage id={RENT_CAR_LINK.text} />
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </DivStyled>
    );
};
const DivStyled = styled.div`
    &.default-desktop-navbar {
        background: linear-gradient(
            180deg,
            #011a40 15.33%,
            rgba(1, 17, 64, 0) 91.26%
        );
        height: 122px;
        padding-top: 20px;

        .logo {
            img {
                height: 50px;
            }
        }

        .navbar {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;

            a {
                color: ${styles.fontColorWhite};
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;

                @media screen and (min-width: 992px) {
                    margin: 8px 12px;
                }

                @media screen and (min-width: 1200px) {
                    margin: 12px 16px;
                }

                @media screen and (min-width: 1600px) {
                    margin: 20px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.rent-car-link {
                    margin-top: 4px;

                    button {
                        height: 40px;
                        width: 120px;
                    }
                }
            }
        }
    }
`;

export default DefaultDesktopNavbar;

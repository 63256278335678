import { createContext } from "react";
import { TaxType } from "src/constants";
import { initialState } from "src/containers/booking-form/booking-form.reducer";

type BookingFormContextType = BookingFormReducerState & {
    defaultDeliveryOptions?: {
        pickUp?: SelectOption[];
        return?: SelectOption[];
    };
    onAccessoriesChange?: (items: BookingAccessory[]) => void;
    onSubmitDiscount: (code: string) => Promise<string | undefined>;
    onCancelDiscount: () => void;
    onSubmitCarServices: (payload: CarServicesData) => void;
    onBackToCarServices: (payload: InformationData) => void;
    onSubmitInformation: (payload: InformationData) => void;
    onSubmitBooking: () => Promise<SubmitBookingResult>;
};

export const BookingFormContext = createContext<BookingFormContextType>({
    ...initialState,
    onSubmitDiscount: () => Promise.resolve(undefined),
    onCancelDiscount: () => {},
    onSubmitCarServices: () => {},
    onBackToCarServices: () => {},
    onSubmitInformation: () => {},
    onSubmitBooking: () => Promise.resolve({}),
});

type BookingFormSyncContextType = {
    accessories: BookingAccessory[];
    renterForm: BookingRenterFormData;
    taxType: TaxType;
    onAccessoriesChange: (values: BookingAccessory[]) => void;
    onRenterFormChange: (values: BookingRenterFormData) => void;
    onTaxTypeChange: (value: TaxType) => void;
};

export const BookingFormSyncContext = createContext<BookingFormSyncContextType>(
    {
        accessories: [],
        renterForm: {},
        taxType: TaxType.PERSONAL,
        onAccessoriesChange: () => {},
        onRenterFormChange: () => {},
        onTaxTypeChange: () => {},
    }
);

export const BookingPaymentContext = createContext<any>({});

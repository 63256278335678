export const LOCALE: Record<"EN" | "TH", Locale> = {
    TH: { value: "th", text: "ไทย" },
    EN: { value: "en", text: "English" },
};

// API HEADERS
export const AH_ACCESS_TOKEN = "token";
export const AH_LOCALE = "locale";

// LOCAL STORAGE
export const LS_LOCALE = "locale";

// AXIOS
export const AXIOS_SILENT_ALL = "ALL";

export enum REQUEST_ERRORS {
    ENDPOINT_NOT_FOUND = "ENDPOINT_NOT_FOUND",
    BUSINESS_EXCEPTION = "BUSINESS_EXCEPTION",
    TECHNICAL_EXCEPTION = "TECHNICAL_EXCEPTION",
    UNAUTHENTICATED_EXCEPTION = "UNAUTHENTICATED_EXCEPTION",
    SERVICE_DOWN = "SERVICE_DOWN",
    SESSION_EXIPRED = "SESSION_EXIPRED",
    NETWORK_ERROR = "NETWORK_ERROR",
}

export enum CarLocationServices {
    DIFFERENT_LOCATIONS = "differentLocations",
    SAME_LOCATION = "sameLocation",
    DELIVERY = "delivery",
}

export enum PromotionPriceType {
    ONE_TIME = "oneTime",
    PER_DAY = "perDay",
}

export enum RentWithUsRequestForm {
    INPUT = "input",
    TOR = "tor",
}

export const BRANCH_AVAILABLES_TIMES = [
    "08.00",
    "08.30",
    "09.00",
    "09.30",
    "10.00",
    "10.30",
    "11.00",
    "11.30",
    "12.00",
    "12.30",
    "13.00",
    "13.30",
    "14.00",
    "14.30",
    "15.00",
    "15.30",
    "16.00",
    "16.30",
    "17.00",
];

export enum BookingState {
    CAR_SERVICES = 1,
    INFORMATION = 2,
    PAYMENT = 3,
}

export enum BookingPayType {
    PAY_NOW = "PAY_NOW",
    PAY_AT_COUTNER = "PAY_ON_COUNTER",
}

export enum IdentifierType {
    PERSONAL_CARD = "CID",
    PASSPORT = "passport",
}

export enum TaxType {
    PERSONAL = "INDIVIDUAL",
    COMPANY = "COMPANY",
}

export const REGEX = {
    postalCode: /^[0-9]{5,5}$/g,
    name: /^[\u0E00-\u0E7Fa-zA-Z.\s\-()]*$/gm,
    numberOnly: /^[0-9]*$/g,
    engCharAndNumber: /^[0-9a-zA-Z]*$/gi,
    lineId: /^[0-9a-zA-Z_.-]*$/gi,
};

export const CAR_SIZES: CarSize[] = [
    { name: "Mini", id: "Mini" },
    { name: "Economy Sedan", id: "Economy Sedan" },
    { name: "Compact Sedan", id: "Compact Sedan" },
    { name: "Intermediate Sedan", id: "Intermediate Sedan" },
    { name: "Premium Sedan", id: "Premium Sedan" },
    { name: "Minivan Manual", id: "Minivan Manual" },
    { name: "Minivan Auto", id: "Minivan Auto" },
    { name: "Intermediate Van", id: "Intermediate Van" },
    { name: "Premium Van", id: "Premium Van" },
    { name: "Compact Mpv", id: "Compact Mpv" },
    { name: "Standard Mpv", id: "Standard Mpv" },
    { name: "Compact Crossover", id: "Compact Crossover" },
    { name: "Standard Suv", id: "Standard Suv" },
    { name: "Premium 4Wd Suv", id: "Premium 4Wd Suv" },
    { name: "Smart Cab Truck Standard", id: "Smart Cab Truck Standard" },
    { name: "Smart Cab Truck 4Wd", id: "Smart Cab Truck 4Wd" },
    { name: "Double Cab Truck Standard", id: "Double Cab Truck Standard" },
    { name: "Double Cab Truck 4Wd", id: "Double Cab Truck 4Wd" },
    { name: "Single Cab Truck Standard", id: "Single Cab Truck Standard" },
    { name: "Single Cab Truck 4Wd", id: "Single Cab Truck 4Wd" },
];

export const THAI_DIAL_CODE = "+66";

export enum CAR_SERVIES_METHOD {
    DELIVERY = "DELIVERY",
    SELF_PICKUP = "SELF_PICKUP",
}

export enum SubmitBookingCouponType {
    INVALID = "invalid",
    WAITING_FOR_APPLY = "waiting-for-apply",
}

export const BOOKING_REF_NO = "booking_ref_no";
